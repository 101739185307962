// import lib
import {isEmpty} from './isEmpty';

export const validation = value => {
    let errors = {};
   
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
    if (isEmpty(value.firstname)) {
        errors.firstname = "PROFILE_FIRST_NAMES"
    }
    if (isEmpty(value.lastname)) {
        errors.lastname = "PROFILE_LAST_NAMES"
    }
    if (isEmpty(value.message)) {
        errors.message = "TICKET_MESSAGE"
    }
    if (isEmpty(value.email)) {
        errors.email = "EMAIL_REQUIRED1"
    }else if (!(emailRegex.test(value.email))) {
        errors.email = "EMAIL_INVALID"
    }
    if(isEmpty(value.phone_number)){
        errors.phone_number ="Enter phone number"
    }
    
    return errors;
}