import React, { useEffect } from 'react'
import { Help } from '@material-ui/icons';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import WalletConnectProvider from "@walletconnect/web3-provider";
import { func } from 'prop-types';
import Web3Modal from "web3modal";
import config from "../lib/config"
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const WalletModal = (props) => {
  const [accounts, setaccount] = React.useState();

  // const providerOptions = {
  //   connector: async (ProviderPackage, options) => {
  //     const provider = new ProviderPackage(options);

  //     await provider.enable()

  //     return provider;
  //   }
  // };

  // const provider = new WalletConnectProvider({
  //   rpc: {
  //     97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  //     56: "https://bsc-dataseed.binance.org/",
  //   },
  // });

  // const web3Modal = new Web3Modal({
  //   //  network: "mainnet", // optional
  //   cacheProvider: true, // optional
  //   providerOptions // required
  // });

  useEffect(() => {
    // Get_Activity_list();
  }, []);

  async function handleWallet() {

    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
          window.ethereum.enable().then(async function () {
            const web3 = new Web3(window.web3.currentProvider)
            if (window.web3.currentProvider.isMetaMask === true) {
              await web3.eth.getAccounts(async function (error, result) {
                localStorage.setItem("tfnukukpannft", 'yes');
                localStorage.setItem("wtype", "wallet")
                window.location.reload(false)
              })
            }
          })
        } else {
          //toast.success("Please Add Metamask External", toasterOption);
        }
      } catch (err) { }
    } else {
      // toast.success("Please Add Metamask External", toasterOption);
    }
  }

  // async function connectTrustWallet() {
  //   var web3 = new Web3(window.ethereum);
  //   var provider1 = new WalletConnectProvider({
  //     rpc: {
  //       [56]: "https://bsc-dataseed.binance.org/"
  //     },
  //     chainId: 56
  //   });
  //   provider1.on("connect", () => {
  //     setTimeout(() => window.$('#connect_wallet_modal').modal('hide'), 600);
  //     setTimeout(() => window.location.reload(false), 1200);
  //   });
  //   await provider1.enable();
  //   web3 = new Web3(provider1);
  //   web3.eth.net.getId().then(async res => {
  //     if (res === 56) {
  //       // alert(res)
  //       var getaccounts = await web3.eth.getAccounts();
  //       localStorage.setItem("wtype", "trust")
  //       localStorage.setItem("tfnukukpannft", 'yes');
  //       localStorage.setItem('Metamask1', getaccounts[0]);
  //     } else {
  //       toast.error("Connect BSC Mainnet", toasterOption);
  //       setaccount("")
  //       return false;
  //     }
  //   });
  // }

  async function connectMetamask(type) {
    console.log("=======inin1111",type)
    if (type == "metamask") {
      localStorage.setItem("lout", true)
      if (window.ethereum) {
        var mynetwork = config.networkVersion
        var web3 = new Web3(window.ethereum);
        try {
          if (typeof web3 !== 'undefined') {
            window.ethereum.enable().then(async function () {
              const web3 = new Web3(window.web3.currentProvider)
              if (window.web3.currentProvider.networkVersion == mynetwork) {
                if (window.web3.currentProvider.isMetaMask === true) {
                  await web3.eth.getAccounts(async function (error, result) {
                    localStorage.setItem("tfnukukpannft", 'yes');
                    localStorage.setItem("address", result[0]);
                    localStorage.setItem("lout", true)
                    localStorage.setItem("wtype", "metamask")
                    setTimeout(() => window.$('#connect_wallet_modal').modal('hide'), 600);
                    setTimeout(() => window.location.reload(false), 1200);
                    toast.success("Wallet Connected Successfully", toasterOption)
                    // window.location.href="/home"
                  })
                }
              } else {
                var chainId = web3.utils.toHex(mynetwork);
                var switchres = await web3.currentProvider.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: chainId }]
                });
                if (window.web3.currentProvider.networkVersion == mynetwork) {
                  if (window.web3.currentProvider.isMetaMask === true) {
                    await web3.eth.getAccounts(async function (error, result) {
                      localStorage.setItem("tfnukukpannft", 'yes');
                      localStorage.setItem("address", result[0]);
                      localStorage.setItem("lout", true)
                      localStorage.setItem("wtype", "metamask")
                      setTimeout(() => window.$('#connect_wallet_modal').modal('hide'), 600);
                      setTimeout(() => window.location.reload(false), 1200);
                      toast.success("Wallet Connected Successfully", toasterOption)
                      // window.location.href="/home"
                    })
                  }
                }
                //toastAlert('error',"Please Connect to Mainnet",'error')
              }
            }).catch(async (err) => {
              if (err && (err.code == "4902" || err.code == 4902)) {
                await web3.eth.getAccounts(async function (error, result) {
                  window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                      chainId: config.chainid, //"0x"+config.networkversion,  ///        
                      chainName: config.chainname,
                      nativeCurrency: {
                        name: config.chainsymbol,
                        symbol: config.chainsymbol,
                        decimals: 18
                      },
                      rpcUrls: [config.rpcUrls],
                      blockExplorerUrls: [config.blockExplorerUrls]
                    }]
                  }).then((succ) => {
                    toast.success("Wallet Connected Successfully", toasterOption)
                    localStorage.setItem("tfnukukpannft", 'yes');
                    localStorage.setItem("address", result[0]);
                    localStorage.setItem("lout", true)
                    localStorage.setItem("wtype", "metamask")
                    // window.location.href="/home"
                  })
                    .catch((error) => {
                      console.log(error)
                    })
                })
              } else {
                console.log(err, "=============================err1")
                toast.warn("Please Connect Metamask External", toasterOption)
              }
            })
          } else {
            toast.warn("Please Add Metamask External", toasterOption)
          }
        } catch (err) {
          console.log(err, "=============================err0")
          toast.warn("Please Connect Metamask External", toasterOption)
        }
      } else {
        toast.warn("Please Add Metamask External", toasterOption)
      }
    } else if (type == "wallet") {
      console.log('wallet')
      var provider = new WalletConnectProvider({
        rpc: {
          [config.livechainid]: config.liverpcUrls
        },
        chainId: config.livechainid
      });
      console.log(provider,'providerwallet')
      provider.on("connect", () => {
        setTimeout(() => window.$('#connect_wallet_modal').modal('hide'), 600);
        setTimeout(() => window.location.reload(false), 1200);
      });
      await provider.enable();
      var web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      console.log(result[0],'addresswallet')
      if (config.networkVersion == network) {
        localStorage.setItem("wtype", type)
        localStorage.setItem("tfnukukpannft", 'yes');
        localStorage.setItem("address", result[0]);
        localStorage.setItem("lout", true)
        toast.success("Wallet Connected Successfully", toasterOption)
        // window.location.href="/home"

      } else {
        await provider.disconnect();
        toast.error('error', "please select binance chain network on your wallet", toasterOption);
      }
    }
  }


  return (
    <div className="modal fade primary_modal" id="wallet_modal" tabIndex="-1" role="dialog" aria-labelledby="wallet_modal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="wallet_modal">Connect to a wallet</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="wallet_panel" onClick={() => connectMetamask("metamask")} >
              <h2>Metamask</h2>
              <img src={require("../assets/images/icon_meta_mask.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel" onClick={() => connectMetamask("wallet")}>
              <h2>TrustWallet</h2>
              <img src={require("../assets/images/TWT.png")} alt="Icon" height="28" width="28" className="img-fluid" />
            </div>
            {/*   <div className="wallet_panel">
              <h2>TrustWallet</h2>
              <img src={require("../assets/images/icon_trust_wallet.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>MathWallet</h2>
              <img src={require("../assets/images/icon_math_wallet.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>TokenPocket</h2>
              <img src={require("../assets/images/icon_pp.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>WalletConnect</h2>
              <img src={require("../assets/images/icon_wallet_connect.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>Binance Chain Wallet</h2>
              <img src={require("../assets/images/icon_binance_chain_wallet.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_modal_footer">
              <a href="#" target="_blank"><Help/><span>Learn how to connect</span></a>
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WalletModal;