import React, {
  forwardRef,
  useEffect,
  useImperativeHandle
} from 'react';
import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';
import {
  AddLikeAction,
  GetLikeDataAction
} from '../../actions/v1/token';
import BEP721   from  '../../ABI/BEP721.json';
import {
  getCurAddr
} from '../../actions/v1/User';
import {
  convertion,
  BurnField,
  TokenCounts_Get_Detail_Action
} from '../../actions/v1/token';
import { Button, TextField } from '@material-ui/core';
import {
  useHistory
} from "react-router-dom";
import { toast } from 'react-toastify';
import {getmylog} from "../../helper/walletconnect";
toast.configure();
let toasterOption = config.toasterOption;

export const BurnRef = forwardRef((props, ref) => {
  const history = useHistory();
  const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
  const [onwer_price,set_owner_price]=React.useState({})
  const [burnLoading, setBurnLoading] = React.useState('empty');
  const[noofitems,setnoofitem]=React.useState(1);
  const [showingLoader, setshowingLoader] = React.useState(false);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = React.useState({});
  const [OwnersDetailFirst, Set_OwnersDetailFirst] = React.useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = React.useState({});
  const [Bids, Set_Bids] = React.useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = React.useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = React.useState(0);
  var  [item, Set_item] = React.useState({}); 
  var  [item1, Set_item1] = React.useState({}); 
  const [Burndata,setBurndata] = React.useState(0);
  const [Provider, SetProvdier] = React.useState();
  const [BEP721Contract, setBEP721Contract] = React.useState(); 
  const [web3, setweb3] = React.useState();

  useEffect(()=>{
    setmyprovdier()
  },[])

  const setmyprovdier =(async()=>{
    var mydata = await getmylog();
    if(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!="") {
        SetProvdier(mydata);
        let web3                = new Web3(mydata.provider);
setweb3(web3);
let BEP721Contract    = new web3.eth.Contract(BEP721, config.singleContract)
setBEP721Contract(BEP721Contract);
    }
  })

  var {
      item,
      UserAccountAddr,
      GetUserBal,
     // Set_item
  } = props;
  async function FormSubmit(item,Burntoken){
      console.log("FormSubmit:",Burntoken)
      if(Provider) {
        var web3 = new Web3(Provider);
        if (web3&&web3.eth) {
          var connectwallet=localStorage.getItem("tfnukukpannft");
          if(!connectwallet){
            toast.error("Please connect to a Metamask wallet", toasterOption);
            return false;
          }
          console.log("noofitems",noofitems)
         
          if(noofitems == "" ){
            toast.error("Burn token is not allow to empty", toasterOption);
            return false;
          }else  if(noofitems == 0){
            toast.error("Burn token must be greater than zero", toasterOption);
            return false;
          }
            var CoursetroContract = new web3.eth.Contract(BEP721,config.singleContract);
            let contract = (Burntoken.type === config.singleType) ? config.singleContract : config.multipleContract; 
            setBurnLoading('processing');
            CoursetroContract.methods
                .burn(
                  Burntoken.tokenCounts,
                )
                .send({ from: UserAccountAddr })
                .then(async (result) => {
                    setBurnLoading('done');
                    console.log('result : ', result);
                    var postData = {
                    tokenOwner: UserAccountAddr,
                    tokenCounts: Burntoken.tokenCounts,
                    blockHash: result.blockHash,
                    transactionHash: result.transactionHash,
                    contractAddress:Burntoken.contractAddress,
                    type:Burntoken.type,
                    balance:noofitems,
                    currAddr:UserAccountAddr,
                    quant:Burntoken.balance
                    }
                    var updateBurnField = await BurnField(postData)
                    if(updateBurnField){
                    toast.success('Burned successfully',toasterOption)
                    document.getElementById('closeburn').click()
                    history.push('/nft')
                    }
                })
                .catch((error) => {
                  setBurnLoading('try');
                  console.log('error : ', error);
                  toast.error('Order not placed', toasterOption);
                    // setTimeout(() => window.location.reload(false), 900);
              })
        }
     }

  }
  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = Provider.address;
    setshowingLoader(true)
    var Resp = await TokenCounts_Get_Detail_Action(payload);
    setTimeout(() => {
      setshowingLoader(false)
    }, 2000);

    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
      var TokenResp = Resp.data.Detail.Resp;
      if (
        TokenResp
        && TokenResp.Token
        && TokenResp.Token[0]
        && TokenResp.Token[0].tokenowners_current
      ) {
        for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          set_owner_price(element)
          if (element.tokenPrice > 0 && element.tokenOwner != curAddr) {
            Set_BuyOwnerDetailFirst(element);
            break;
          }
          if (element.tokenPrice > 0 && element.tokenOwner == curAddr) {
            Set_OwnersDetailFirst(element);
            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);
      if (TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }

      var IndexVal = -1;
      if (TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(val => (val.tokenOwner.toString() == curAddr.toString() && val.balance > 0));
      }
      if (IndexVal > -1) {
        Set_MyTokenBalance(tokenowners_all[IndexVal].balance);
        Set_MyTokenDetail(tokenowners_all[IndexVal])
      }
      else {
        Set_MyTokenDetail({});
        Set_MyTokenBalance(0);
      }

      if (TokenResp.Token && TokenResp.Token[0]) {
        Set_item1(TokenResp.Token[0]);
      }
  }
  }
  const handleChange = (e) => {    
      if(MyTokenDetail>=e && e.target && typeof e.target.value != 'undefined'){
        setnoofitem(e.target.value)
        setBurnLoading('init');
      }
      else if(e.target.value == 0){
        setBurnLoading('zero');
        setnoofitem(0)
      }
      else if(e.target.value == "" ){
        setBurnLoading('empty');
        setnoofitem("")
      }else if(e.target.value == undefined ){
        setBurnLoading('empty');
        setnoofitem("")
      }
      else{
        setBurnLoading('errors');
        setnoofitem("")
      }
  }
  useImperativeHandle(
      ref,
      () => ({
          async Burn_Click(item,burndata) {
            var connectwallet=localStorage.getItem("tfnukukpannft");
            if(!connectwallet){
              toast.error("Please connect to a wallet", toasterOption);
              return false;
            }
            setBurndata(burndata);
              var curAddr = Provider.address;
                  var payload = {
                    curAddr: curAddr,
                    tokenCounts: item.tokenCounts
                  };
                  TokenCounts_Get_Detail_Call(payload);
              window.$('#burn_token_modal').modal('show');
          },
      }),
  )
  return (
      <div class="modal fade primary_modal" id="burn_token_modal" tabindex="-1" role="dialog" aria-labelledby="burn_token_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
    <h5 class="modal-title" id="burn_token_modalLabel">Burn token</h5>
    <button type="button" id="closeburn" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body px-0">
      <form className="bid_form">
      {/* <div className="px-4">
          <p className="text-light text-center" style={{color:'black',fontWeight:'bold'}}>{MyTokenDetail.balance} Tokens Available</p>
          </div>  */}
        <div className="px-4">
          <p className="text-light">Are you sure to burn this token? This action cannot be undone. Token will be transfered to zero address</p>
        </div>  
        <div className="px-4 mt-4 pt-2">               
          <div className="text-center mt-3">
            <Button 
              className="burn_btn_red primary_btn btn-block" 
              onClick={(() => FormSubmit(item,MyTokenDetail))}
              disabled = { (burnLoading == 'done' || burnLoading=='processing' ||burnLoading == 'errors') }
              >
                {burnLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                {burnLoading == 'init' && 'Continue'}
                {burnLoading == 'processing' && 'In-progress...'}
                {burnLoading == 'done' && 'Done'}
                {burnLoading == 'try' && 'Try Again'}
                {burnLoading == 'errors' && 'Check Balance'}
                {/* {burnLoading == 'zero' && "Qty can't be Zero"} */}
                {burnLoading == 'empty' && "Continue"} 
                
              </Button>
            <Button className="btn_outline_grey cancel_btn btn-block" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>Cancel</Button>
          </div>
        </div>

      </form>
    </div>
  </div>
  </div>
</div>
  )
})

