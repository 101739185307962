import React,{useEffect} from 'react'
import { Button } from "@material-ui/core";
const CookiesModal =(props)=> {
const {onsetcokkie} = props;
  useEffect(() => {
    // Get_Activity_list();
  }, []);

  

  return (
    <div className="cookies_modal">
      <h3>This website uses cookies.</h3>
      <p>We use cookies to analyze website traffic and optimize your website experience. By accepting our use of cookies, your data will be aggregated with all other user data.</p>
      <div>
        <Button className="primary_btn mr-3 cookie_decline_btn" onClick={()=>onsetcokkie("decline")}>Decline</Button>
        <Button className="primary_btn cookie_accept_btn" onClick={()=>onsetcokkie("accept")}>Accept</Button>
      </div>
    </div>
)
}

export default CookiesModal;