import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Link, useParams, useHistory } from "react-router-dom";
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";
import Countdown, { zeroPad } from "react-countdown";
import $ from 'jquery';
import axios from 'axios';
import config from '../lib/config';
import validation from './validation';
import {
  getCurAddr,
  UserProfile_Update_Action,
  ParamAccountAddr_Detail_Get,
} from '../actions/v1/User';

import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import HeaderSearch from './separate/HeaderSearch'
import TokenItem from './separate/Token-Item';
import BidPopup from './separate/Bid-Popup';
import ConnectWallet from './separate/Connect-Wallet';

import ReactLoading from 'react-loading';

import Web3 from 'web3';
import '@metamask/legacy-web3'

import { toast } from 'react-toastify';
import isEmpty from "lib/isEmpty";
toast.configure();
let toasterOption = config.toasterOption;
const drawerWidth = 240;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 6.2 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  }
});

const NFT = (props) => {
  const [open, setOpen] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [validateError, setValidateError] = useState({});

  const handleDrawerOpen = () => {
    console.log("data---------------")
    setOpen(!open)
  };
  const handleMenu = event => {
    console.log("----event", event)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();

  const [name, Set_name] = useState('');
  const [mobile, setphoneNumber] = useState("");
  const [personalsite, Set_personalsite] = useState('');
  const [customurl, Set_customurl] = useState('');
  const [email, Set_email] = useState('');
  const [bio, Set_bio] = useState('');
  const [twitter, Set_twitter] = useState('');
  const [youtube, Set_youtube] = useState('');
  const [facebook, Set_facebook] = useState('');
  const [instagram, Set_instagram] = useState('');

  // wallet related : common state
  const [WalletConnected, Set_WalletConnected] = useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = useState('');
  const [UserAccountBal, Set_UserAccountBal] = useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = useState('');

  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');

  const [TokenFile, setTokenFile] = React.useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = React.useState("");
  const [TokenFilePreUrl, setTokenFilePreUrl] = React.useState("");

  const selectFileChange = (e) => {
    var validExtensions = ["png", 'gif', 'webp', 'mp4', 'mp3']; //array of valid extensions
    if (e.target && e.target.files) {
      var reader = new FileReader()
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) == -1) {
        toast.error("Only these file types are accepted : " + validExtensions.join(', '), toasterOption);
        $("input[name='image']").val("");
        return false;
      }
      else {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 30) {
          toast.error("File size exceeds 30 MB", toasterOption);
          $("input[name='image']").val("");
          return false;
        } else {
          setTokenFile(file);
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            if (reader.result) {
              setTokenFilePreReader(reader.result);
            }
          }.bind(this);
          setTokenFilePreUrl(e.target.value);
        }
      }
    }
  }

  const inputChange = (e) => {

    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      var value = e.target.value;
      console.log("phoneno", e.target.value)
      AddressUserDetails[e.target.name] = value
      Set_AddressUserDetails(AddressUserDetails);
      setValidateError(validation(AddressUserDetails))
      CreateItemValidation();
    }
  }
  const CreateItemValidation = () => {
  }

  async function AfterWalletConnected() {
    var currAddr = await getCurAddr();
  }
  const FormSubmit = async () => {
    // setValidateError(validation(AddressUserDetails))
    // if(isEmpty(validateError)){
      if(AddressUserDetails.email==""){
        setValidateError(validation(AddressUserDetails))
        return false;
      }
    var currAddr = await getCurAddr();
    if (currAddr) {
      var web3 = new Web3(window.ethereum)
      web3.eth.personal
        .sign(
          `Your Created profile as ${AddressUserDetails.name} and Your url is ${config.Front_URL}/${customurl}`,
          currAddr,
          `Your Created profile as ${AddressUserDetails.name} and Your url is ${config.Front_URL}/${customurl}`
        )
        .then(async () => {
          var formData = new FormData();
          if (TokenFile) {
            formData.append('Image', TokenFile);
          }
          formData.append('addr', currAddr);
          formData.append('plotno', AddressUserDetails.plotno);
          formData.append('addressline', AddressUserDetails.addressline);
          formData.append('country', AddressUserDetails.country);
          formData.append('state', AddressUserDetails.state);
          formData.append('city', AddressUserDetails.city);
          formData.append('postalcode', AddressUserDetails.postalcode);
          setphoneNumber(AddressUserDetails.mobile)
          formData.append('name', AddressUserDetails.name);
          formData.append('mobile', mobile);
          formData.append('personalsite', AddressUserDetails.personalsite);
          formData.append('customurl', AddressUserDetails.customurl);
          formData.append('email', AddressUserDetails.email);
          formData.append('bio', AddressUserDetails.bio);
          formData.append('twitter', AddressUserDetails.twitter);
          formData.append('youtube', AddressUserDetails.youtube);
          formData.append('facebook', AddressUserDetails.facebook);
          formData.append('instagram', AddressUserDetails.instagram);
          console.log('formData : ', formData);
          var Resp = await UserProfile_Update_Action(formData);
          if (Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.type == 'success') {
            setTimeout(() => { history.push('/my-items'); }, 2000)
          }
        })
    }
  // }
  }
  async function submitmail() {
    if (AddressUserDetails.email != "" && AddressUserDetails.email != undefined) {
      var currAddr = await getCurAddr();
      var data = {
        email: AddressUserDetails.email,
        useraddress: currAddr
      }

      // var Resp = await verificationuser(data)
      // if (Resp) {
      //   toast.success("Request Submitted Successfully", toasterOption);
      // }
    } else {
      toast.error("Invalid Mail Id", toasterOption);
    }
  }
  const { classes, theme } = props;
  // const open = Boolean(anchorEl);

  // Countdown Timer
  const currentDate = new Date();
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">d</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">h</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">m</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">s</span></span>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
      <ScrollToTopOnMount />
      <CssBaseline />

      {/* Header APP */}
      <HeaderDashboard
        classes={classes}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        handleMenu={handleMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />

      {/*  side Menu */}
      <SidebarLinksAfterlogin classes1={classes} open={open} />
      <div className="overlay"></div>

      <main className={classes.content + " dashContent"}>
        <div className={classes.toolbar} />
        <div className="dashPages">
          <div className="d-flex align-items-center mb-4 justify-content-between">
            <h2 className="inner_title">Edit Profile</h2>
            <Link class="goback_link" to="/my-items">
              <i class="bi bi-chevron-left"></i><span>Back</span>
            </Link>
          </div>
      
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>              
              <div class="holder">
                {
                  AddressUserDetails && AddressUserDetails.image && AddressUserDetails.image != ''
                    ?
                    <img src={config.Back_URL + '/profile/' + AddressUserDetails.image} alt="logo" id="imgPreview" className="img-fluid" />
                    :
                    <img src={TokenFilePreReader!=""?TokenFilePreReader:require("../assets/images/profilepic.jpg")} alt="logo" id="imgPreview" className="img-fluid" />
                }
{/* {
                    AddressUserDetails.image == '' || TokenFile ?
                    <img src={require("../assets/images/profilepic.jpg")} alt="logo" id="imgPreview" className="img-fluid"/>
                    :
                   <img src={config.Back_URL + "/profile/"+AddressUserDetails.image} alt="" className="img-fluid" />                   
                  } */}
              </div>
              <div className="profile_edit_panel">
                <div className="profile_edit_icon">
                  <i class="fas fa-pencil-alt">
                  <input type="file" name="image" id="photo" required="true" onChange={selectFileChange} />
                  </i>
                </div>
              </div>
              {/* <div className="profile_edit_panel">
                <div className="profile_edit_icon">
                  <i class="fas fa-pencil-alt"></i>
                </div>
                <input type="file" name="image" id="photo" required="true"  onChange={selectFileChange}/>
              </div> */}
            </GridItem>
            <GridItem xs={12} sm={9} md={9}>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Display Name</label>
                    <input type="text"
                      className="form-control primary_inp"
                      id="name"
                      name="name"
                      placeholder="Enter your display name"
                      autoComplete="off"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.name != 'undefined' ? AddressUserDetails.name : ""}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Personal site or portfolio(http://{personalsite})</label>
                    <input type="text" className="form-control primary_inp"
                      id="personalsite"
                      name="personalsite"
                      autoComplete="off"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.personalsite != "undefined" ? AddressUserDetails.personalsite : ""}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Custom URL({config.Front_URL}/{customurl})</label>
                    <input type="text" className="form-control primary_inp"
                      id="customurl"
                      name="customurl"
                      autoComplete="off"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.customurl != 'undefined' ? AddressUserDetails.customurl : ""}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Email</label>
                    <input type="text" className="form-control primary_inp"
                      id="email"
                      name="email"
                      autoComplete="off"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.email != "" ? AddressUserDetails.email : ""}
                      placeholder="Your email for marketplace notifications" />
                      {validateError.email && <p className="text-danger">{validateError.email}</p>}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Bio</label>
                    <input type="text" className="form-control primary_inp"
                      id="bio"
                      name="bio"
                      autoComplete="off"
                      placeholder="Tell about yourself in a few words"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.bio != "undefined" ? AddressUserDetails.bio : ""}
                    />
                  </div>
                  {/* <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Mobile</label>
                    <PhoneInput placeholder="Enter phone number" value={mobile}  onChange={setphoneNumber}/>

                  </div> */}
                </div>
                {/*<div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Verification</label>
                    <div className="d-flex align-items-start">
                      {AddressUserDetails.emailverified == true && AddressUserDetails.verificationstatus == "verified" ?
                        <Button className="primary_btn my-2" >Verified</Button>
                        :
                        <Button className="primary_btn my-2" onClick={() => submitmail()}> Get verified</Button>
                      }
                    </div>
                    <p className="text-muted m-0 noteText"><small>Procceed with verification proccess to get more visibility and gain trust on Quam Marketplace. Please allow up to several weeks for the process.</small></p>
                  </div>
                </div>
                 <div className="form-row mt-3">
                  <div className="form-group col-md-6">
                    <label className="primary_label mb-0" htmlFor="name">Contact Address</label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text"  
                     onChange={inputChange}
                     defaultValue={AddressUserDetails.plotno!="undefined"?AddressUserDetails.plotno:""}
                     autoComplete="off"
                     name="plotno" id="plotno" className="form-control primary_inp" placeholder="House/Plot Number"/>
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text"  
                    autoComplete="off"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.addressline!="undefined"?AddressUserDetails.addressline:""}
                    name="addressline" id="addressline" className="form-control primary_inp" placeholder="Address Line"/>
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text"  
                     autoComplete="off"
                     onChange={inputChange}
                     defaultValue={AddressUserDetails.country!="undefined"?AddressUserDetails.country:""}
                    name="country" id="country" className="form-control primary_inp" placeholder="Country"/>
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text"  
                     autoComplete="off"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.state!="undefined"?AddressUserDetails.state:""}
                    name="state" id="state" className="form-control primary_inp" placeholder="State"/>
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text" 
                     autoComplete="off" 
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.city!="undefined"?AddressUserDetails.city:""}
                    name="city" id="city" className="form-control primary_inp" placeholder="City"/>
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text"  autoComplete="off"
                    onChange={inputChange}
                    defaultValue={AddressUserDetails.postalcode!="undefined"?AddressUserDetails.postalcode:""}
                    name="postalcode" id="postalcode" className="form-control primary_inp" placeholder="Postal Code"/>
                  </div>
                </div> */}
                <div className="form-row mt-4">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Social Media Links</label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control primary_inp"
                      id="twitter" name="twitter"
                      placeholder="Twitter account address"
                      onChange={inputChange}
                      autoComplete="off"
                      defaultValue={AddressUserDetails.twitter != "undefined" ? AddressUserDetails.twitter : ""}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control primary_inp"
                      id="youtube"
                      name="youtube"
                      placeholder="Youtube channel address"
                      onChange={inputChange}
                      autoComplete="off"
                      defaultValue={AddressUserDetails.youtube != "undefined" ? AddressUserDetails.youtube : ""}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control primary_inp"
                      id="facebook"
                      name="facebook"
                      onChange={inputChange}
                      autoComplete="off"
                      defaultValue={AddressUserDetails.facebook != "undefined" ? AddressUserDetails.facebook : ""}
                      placeholder="Facebook account address" />
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control primary_inp"
                      id="instagram" name="instagram" placeholder="Instagram address"
                      onChange={inputChange}
                      autoComplete="off"
                      defaultValue={AddressUserDetails.instagram != "undefined" ? AddressUserDetails.instagram : ""}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <Button type="button" className="primary_btn my-2" onClick={() => FormSubmit()}>Update Profile</Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </main>


      <WalletModal />

    </div>
  );
}

NFT.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(NFT);
