let EnvName          = "production";
let Front_URL        = "";
let Back_URL         = "";
let v1Url            = "";
let limit            = "12";
let limitMax         = "3000";
let fee              = 2500000000000000000;
let decimalvalues    = 1000000000000000000;
let toFixed          = 6;
let IPFS_IMG         = "https://ipfs.io/ipfs";
const singleType     = 721;
const multipleType   = 1155;
var providercon      = null;
let networkVersion   = "56"; //mainnet -56,testnet-97
let currencySymbol   = "BNB";
let equalTokenSymbol = "WBNB";
let tokenSymbol      = "KUKU";
const ownerAddr      = "0x3EDa8adee9D8a2BF61717F36Bc5Ca5429Ffa09c3";
let liverpcUrls = "https://bsc-dataseed.binance.org/";
let livechainid ="56";

 
//mainnet
const lottery ="0x912C6C618bd21cf101Ef11479AE4b2945C0D86E0";
const lotterAdmin    ="0xb831468Cc2e05b892d0A2F87314df319a404EB3f";
const lotteryToken        = "0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538";

const singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517"; // testnet 0x96e4ED44bc0dA71e61404920778Ee4C0e1C62b71
const WbnbtokenAddr  = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // testne  t0xd38745B8B190Fb25B4d195ea299E64EebDFa227c
const PankukuAddr    = "0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538"; //testnet  0x7b3064583157f29a9c74b4218E94aDBb3a6aeD38


// lotteryContract -Demo
// const lotteryToken   = "0x7b3064583157f29a9c74b4218E94aDBb3a6aeD38";
// const lottery        = "0xB6F4E2534ACf1baC0f6aD698E5A84c31d2F66093";
// const lotterAdmin    ="0x3778EcF0b729ecd4DdcA96e2D9f588314Ad73F6D";

// const singleContract = "0x96e4ED44bc0dA71e61404920778Ee4C0e1C62b71"; // testnet 
// const WbnbtokenAddr  = "0xd38745B8B190Fb25B4d195ea299E64EebDFa227c"; // testne  
// const PankukuAddr    = "0x7b3064583157f29a9c74b4218E94aDBb3a6aeD38"; //testnet  


const userContract721  = "";
const userContract1155 = "";
let toasterOption = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};
if (EnvName === "production") {
  Front_URL = "https://nft.pankuku.net";
  Back_URL  = "https://api.pankuku.net/";
  v1Url     = "https://api.pankuku.net/v1";
  IPFS_IMG  = "https://ipfs.io/ipfs";

} else if (EnvName === "demo") {
  Front_URL = "http://212.71.233.123";
  Back_URL  = "http://212.71.233.123:5325/";
  v1Url     = "http://212.71.233.123:5325/v1";
  IPFS_IMG  = "https://ipfs.io/ipfs";
} else {
  Front_URL = "http://localhost:3000";
  Back_URL = "http://localhost:2057/";
  v1Url = "http://localhost:2057/v1";
  // Back_URL  = "http://212.71.233.123:5325/";
  // v1Url     = "http://212.71.233.123:5325/v1";
  IPFS_IMG  = "https://ipfs.io/ipfs";
}
  
let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  vUrl: v1Url,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  networkVersion: networkVersion,
  currencySymbol: currencySymbol,
  tokenSymbol: tokenSymbol,
  equalTokenSymbol: equalTokenSymbol,
  toasterOption: toasterOption,
  limit: limit,
  limitMax: limitMax,
  singleContract: singleContract,
  userContract721: userContract721,
  userContract1155: userContract1155,
  ownerAddr: ownerAddr,
  singleType: singleType,
  multipleType: multipleType,
  ipfsurl: IPFS_IMG,
  providercon: providercon,
  WbnbtokenAddr: WbnbtokenAddr,
  PankukuAddr: PankukuAddr,
  fee: fee,
  lotteryToken: lotteryToken,
  lottery: lottery,
  LotterytokenSymbol: "KUKU",
  liverpcUrls:liverpcUrls,
  livechainid: livechainid,
};

export default key;
 // "homepage": "https://nft.pankuku.net",