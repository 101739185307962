import React, { useState, useEffect } from "react";
import PropTypes, { func } from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Link,useLocation,useHistory } from "react-router-dom";
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import BEP721 from '../ABI/BEP721.json'
import Datetime from 'react-datetime';
import moment from "moment"
import Compressor from 'compressorjs';
import "react-datetime/css/react-datetime.css";
import {
  GetCategoryAction,
  CreateTokenValidationAction,
  CreateCollectionValidationAction,
  TokenAddItemAction,
  TokenAddOwnerAction,
  TokenCount_Get_Action,
  ipfsImageHashGet,
  ipfsmetadatafunciton
} from '../actions/v1/token';

import {
  getCurAddr
} from '../actions/v1/User';
import axios from 'axios';
import config from '../lib/config';
import { toast } from 'react-toastify';
import ConnectWallet from './separate/Connect-Wallet';
import {getmylog} from '../helper/walletconnect';
toast.configure();
let toasterOption      = config.toasterOption;
const singleContract   = config.singleContract;
const SingleType       = config.singleType;
const Back_URL         = config.Back_URL;
const drawerWidth      = 240;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const price = [
  { value: config.currencySymbol, label: config.currencySymbol },
  { value: config.tokenSymbol, label: config.tokenSymbol }
]
const bidprice = [
  { value: config.tokenSymbol, label: config.tokenSymbol },
  { value: config.equalTokenSymbol, label: config.equalTokenSymbol }
]

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 6.2 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  }
});

const CreateSingle =(props) => {
  const [open,setOpen]         = useState(true)
  const [anchorEl,setAnchorEl] = useState(null)

  const handleDrawerOpen = () => {
    setOpen(!open)
  };

  const handleDrawerClose = () => {
        setOpen(true)
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
setAnchorEl(null);
  };

  const history                                      = useHistory()
  const [startDate, setStartDate]                    = useState(new Date());
  const [endDate, setEndDate]                        = useState(new Date());
  const [WalletConnected, Set_WalletConnected]       = useState('false');
  const [UserAccountAddr, Set_UserAccountAddr]       = useState('');
  const [UserAccountBal, Set_UserAccountBal]         = useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts]                     = useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr]   = useState('');
  const [ipfshash, setIpfsHash]                      = useState("")
  const [ipfshashurl, setipfshashurl]                = useState('');

  var pathVal = '';
  const location = useLocation();
  if(location.pathname) {
    if(location.pathname.split('/').length >= 2) {
      pathVal = location.pathname.split('/')[1];
    }
  }
  const [location_pathname, Set_location_pathname]     = useState(pathVal);
  var CollectibleType_val                              = SingleType;
  var ContractAddressUser_val                          = config.singleContract;

  const [CollectibleType, Set_CollectibleType]         = useState(CollectibleType_val);
  const [ContractAddressUser, set_ContractAddressUser] = useState(ContractAddressUser_val);
  const [FormSubmitUserClicked, Set_FormSubmitUserClicked] = useState(false);

  const [TokenCollection, setTokenCollection]          = useState({
    addr:'',
    img:'site.png',
    name:'Hentai Holiday'
  });
  const [TokenOwner, setTokenowner]                    = useState({
    addr:'',
    img:'',
    name:''
  });
  const [TokenCreater, setTokenCreater]                = useState({
    addr:'',
    img:'',
    name:''
  });
  var myDate                               = new Date();
  var newdat                               = myDate.setDate(myDate.getDate() + parseInt(1));
  var initialEnd                           = new Date(newdat);
  const [StartDate, Set_StartDate]         = useState('Right after listing');
  const [EndDate, Set_EndDate]             = useState('1 Day');
  const [MintHashVal, Set_MintHashVal]     = useState('');
  const [TokenQuantity, Set_TokenQuantity] = useState(1);
  const [TokenBid, setTokenBid]            = useState(true);
  const [MinimumBid, Set_MinimumBid]       = useState(0);
  const [Clocktime, set_Clocktime]         = useState('');
  const [EndClocktime, set_EndClocktime]   = useState(initialEnd);
  const [UnLockcontent, Set_UnLockcontent] = useState("");
  const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);

  const [SingleContractAddressAdmin, set_SingleContractAddressAdmin]     = useState(config.singleContract);
  const [TokenCount, Set_TokenCount]                                     = useState(0);
  const [ApproveCallStatus, setApproveCallStatus]   = useState('init');
  const [MintCallStatus, setMintCallStatus]         = useState('init');
  const [SignCallStatus, setSignCallStatus]         = useState('init');
  const [SignLockCallStatus, setSignLockCallStatus] = useState('init');
  const [CollectionStatus, setCollectionStatus]     = useState('init');
  const [TokenType, setTokenType]                   = useState('Single');
  const [ValidateError, setValidateError]           = useState({TokenPrice:''});
  const [PutOnSale, setPutOnSale]                   = useState(false);
  const [PutOnSaleType, setPutOnSaleType]           = useState('');
  const [TokenCategory, setTokenCategory]           = useState('');
  const [CategoryOption, setCategoryOption]         = useState(0);
  const [TokenPrice, setTokenPrice]                 = useState(0);
  const [YouWillGet, Set_YouWillGet]                = useState(0);
  const [TokenName, setTokenName]                   = useState('');
  const [TokenDescription, setTokenDescription]     = useState('');
  const [TokenRoyalities, setTokenRoyalities]       = useState(10);
  const [TokenProperties, setTokenProperties]       = useState('');
  const [TokenFile, setTokenFile]                   = useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = useState("");
  const [TokenFilePreUrl, setTokenFilePreUrl]       = useState("");
  const [ipfsimg, setIpfsImg]                       = useState(null)
  const [CollectionFile, setCollectionFile]         = useState("");
  const [CollectionFilePreReader, setCollectionFilePreReader] = useState("");
  const [CollectionFilePreUrl, setCollectionFilePreUrl]       = useState("");
  const [Dispname, setDispname]                     = useState('');
  const [Symbol, setSymbol]                         = useState('');
  const [Colsdescription, setColsdescription]       = useState('');
  const [Shorturl, setShorturl]                     = useState('');
  const [digitalContract,setdigitalContract]        = useState('');
  const [newcollectionStatus,setnewCollectionStatus]= useState(0);
  const [ipfsmetatag,set_ipfsmetatag]               = useState('');
  const [usdval,setusd]                             = useState(0);
  const [biddingtoken, set_biddingtoken]            = useState("BNB")
  const [tokenWill, setTokenWill]                   = useState(0);
  const [Categorylist, setCategorylist]             = useState([]);
  const [servicefee, setservicefee]                 = useState(0);
  const [Provider, setProvider] = useState();
  const [compressedFile,setcompressedFile] = useState();



    const { classes, theme } = props;
    useEffect(() => {
    }, [
      FormSubmitUserClicked,
      StartDate,
      EndDate,
      TokenQuantity,
      TokenBid,
      MinimumBid,
      Clocktime,
      EndClocktime,
      UnLockcontent,
      Unlockoncepurchased,
      PutOnSale,
      PutOnSaleType,
      TokenCategory,
      TokenPrice,
      TokenName,
      TokenDescription,
      TokenRoyalities,
      TokenProperties,
      TokenFilePreUrl,
      CollectionFile,
      CollectionFilePreUrl,
      ipfsimg,
    ]);
 
    async function getUsd(){
     await axios.get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
           .then(async (response2) => {
            console.log(response2.data.USD,"===================3")
            setusd(response2.data.USD)
       })
   }
   async function getTokendet(){
    try{
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        if(CollectibleType==721){
          var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
          if(biddingtoken==config.currencySymbol){
            var fee = await CoursetroContract.methods.getServiceFee().call();
          } else if(biddingtoken==config.tokenSymbol){
            var fee = await CoursetroContract.methods.getKukuServiceFee().call();
          }else{
            var fee = await CoursetroContract.methods.getServiceFee().call();
          }
        }
        var feeValue = fee/config.decimalvalues;
       setservicefee(feeValue);
      }
    }catch(err){
    }
   }
 
    async function GetCategoryCall() {
      var resp = await GetCategoryAction()
      if (resp && resp.data && resp.data.list) {
        var CategoryOption = [];
        resp.data.list.map((item) => {
         setCategorylist(resp.data.list);
          CategoryOption.push({
            name:'TokenCategory',
            value: item._id,
            label: item.name
          })
        })
        setCategoryOption(CategoryOption)
      }
    }
  
    const changePutOnSaleType = (type) => {
      setPutOnSaleType(type);
      if (type=="FixedPrice") {
        Set_MinimumBid("")
        set_biddingtoken(config.currencySymbol)
      }else if (type="TimedAuction") {
        setTokenPrice("")
        set_biddingtoken(config.tokenSymbol)
      }else{
        Set_MinimumBid("")
        setTokenPrice("")
        set_biddingtoken(config.tokenSymbol)
      }
    };
    const CheckedChange = (e) => {
      if(e && e.target && e.target.name) {
  
        if(e.target.name == 'putonsale') {
          if (PutOnSale == false) {
            setPutOnSale(true);
            if(PutOnSaleType == '') {
              setPutOnSaleType('FixedPrice')
            }
          }
          else {
            setPutOnSale(false);
          }
        }
        else if(e.target.name == 'unlockoncepurchased'){
          if (Unlockoncepurchased == false) {
            Set_Unlockoncepurchased(true);
          }
          else {
            Set_Unlockoncepurchased(false);
          }
        }
      }
    };
    const selectFileChange = (e) => {
      var validExtensions = ["png", 'gif', 'webp', 'mp4', 'mp3']; //array of valid extensions
      if(e.target && e.target.files) {
        var reader = new FileReader()
        var file = e.target.files[0];
        var fileName = file.name;
        var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
        if ($.inArray(fileNameExt, validExtensions) == -1) {
          toast.error("Only these file types are accepted : "+validExtensions.join(', '), toasterOption);
          $("input[name='image']").val("");
          return false;
        }
        else {
          const fileSize = file.size / 1024 / 1024;
          if (fileSize > 30) {
            toast.error("File size exceeds 30 MB", toasterOption);
            $("input[name='image']").val("");
            return false;
          } else {
            if($.inArray(fileNameExt, ["png", "gif", "webp"]) != -1){
              console.log("i am available");
              new Compressor(file, {
                quality: 0.2, 
                convertSize:2000000,
                success: (compressedResult) => {
                  console.log(compressedResult,'compressedResult')
                  setcompressedFile(compressedResult);
                },
              });
              }else{
                setcompressedFile(file)
              }
            setTokenFile(file);
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
              if(reader.result) {
                setTokenFilePreReader(reader.result);
              }
            }.bind(this);
            setTokenFilePreUrl(e.target.value);
          }
        }
      }
    }
    const selectcolsFileChange = (e) => {
      var validExtensions = ['gif',]; 
      if(e.target && e.target.files) {
        var reader = new FileReader()
        var file = e.target.files[0];
        var fileName = file.name;
        var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
        if ($.inArray(fileNameExt, validExtensions) == -1) {
          toast.error("Only these file types are accepted : "+validExtensions.join(', '), toasterOption);
          $("input[name='file']").val("");
          return false;
        }
        else {
          const fileSize = file.size / 1024 / 1024;
          if (fileSize > 30) {
            toast.error("File size exceeds 30 MB", toasterOption);
            $("input[name='file']").val("");
            return false;
          } else {
            setCollectionFile(file);
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
              if(reader.result) {
                console.log('reader.result', reader.result);
                setCollectionFilePreReader(reader.result);
              }
            }.bind(this);
            setCollectionFilePreUrl(e.target.value);
          }
        }
      }
    }
    const selectChange = (e) => {
      console.log('selectChange',e)
      if(e && e.name && e.label && e.value) {
        switch(e.name) {
          case 'TokenCategory1':
            setTokenCategory(e);
            break;
          default:
        }
      }
    }
    function handleChange(e) {
     if(e && e.target && e.target.value) {
       setTokenCategory(e.target.value);
     }
    }
  
    const PriceCalculate = async (data={}) => {
      try{
        var provider = window.ethereum;
        var web3 = new Web3(provider);
        var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
        var weii = await web3.utils.toWei((price).toString()) 
        if(location_pathname=="create-single"){
         var nftContract = new web3.eth.Contract(BEP721,ContractAddressUser);
         if(biddingtoken==config.currencySymbol){
          var fee = await nftContract.methods.getServiceFee().call();
        } else if(biddingtoken==config.tokenSymbol){
          var fee = await nftContract.methods.getKukuServiceFee().call();
        }else{
          var fee = await nftContract.methods.getServiceFee().call();
        }
        }
        var per = (weii * fee) / 1e20;
        var mulWei = parseFloat(weii - per);
        Set_YouWillGet((mulWei / config.decimalvalues).toFixed(config.toFixed));
        setTokenWill((mulWei / config.decimalvalues).toFixed(config.toFixed))
      }catch(err){}
    }
    const collectioninputChange = (e) => {
      if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
        var value = e.target.value;
        switch(e.target.name) {
          case 'Dispname':
            setDispname(value);
          break;
          case 'Symbol':
          setSymbol(value);
          break;
          case 'Colsdescription':
            setColsdescription(value);
            break;
            case 'Symbol':
              setShorturl(value);
              break;
        default:
        }
      }
    }
    const onKeyUp = (e) => {
      var charCode = e.keyCode;
      if((charCode>47 && charCode <58) || (charCode>95 && charCode <106)){
        var ValidateError = {};
        setValidateError(ValidateError);
      }else{
        var ValidateError = {};
        ValidateError.TokenQuantity = '"Quantity" must be a number';
        Set_TokenQuantity("");
        setValidateError(ValidateError);
      }
    }
    const inputChange = (e) => {
      if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
        var value = e.target.value;
        console.log("Enter Valid price",value, isNaN(value),parseFloat(value))
        switch(e.target.name) {
          case 'TokenPrice':
            if(value != '' && isNaN(value) == false && value > 0) {
              setTokenPrice(value);
              PriceCalculate({price:value});
              ValidateError.TokenPrice = '';
              setValidateError(ValidateError);
            } 
            else {
              ValidateError.TokenPrice = 'Enter Valid price';
              setValidateError(ValidateError);
              setTokenPrice('0');
              PriceCalculate({price:0});
            }
            break;
          case 'TokenName':
            setTokenName(value);
            break;
          case 'TokenDescription':
            setTokenDescription(value);
            break;
          case 'TokenRoyalities':
            setTokenRoyalities(value);
            break;
          case 'TokenProperties':
            setTokenProperties(value);
            break;
          case 'UnLockcontent':
            Set_UnLockcontent(value);
            break;
          case 'MinimumBid':
            Set_MinimumBid(value);
            break;
          case 'TokenQuantity':
            Set_TokenQuantity(value);
            break;
          default:
        }
      }
    }
  
    useEffect(() => {
     getUsd();
     getTokendet()
    }, [])
  
    async function ApproveCall() {
      var mydata = await getmylog();
      const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
      // if (!mydata) {
      //   toast.warning("OOPS!..connect Your Wallet", toasterOption);
      //   return false;
      // }
      var result = await web3.eth.getAccounts();
      var currAddr = result[0];
      // var currAddr = window.web3.eth.defaultAccount;
      if (!currAddr) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }
  
      const singleContract    = new web3.eth.Contract(BEP721, config.singleContract);
      const CoursetroContract = singleContract;
      setApproveCallStatus('processing');
  
      await singleContract.methods.setApproval(
        config.singleContract,
        true
      ).send({
        from: Accounts
      })
      .then(async (result) => {
  
        var tokenid = (result && result.events && result.events.Approve
          && result.events.Approve.returnValues
          && result.events.Approve.returnValues.token_id)?result.events.Approve.returnValues.token_id:0;
        Set_TokenCount(tokenid)

        var senddata={
          Image: TokenFile,
          compressImage: compressedFile,
          tokenid:tokenid,
          TokenName:TokenName,
          Address: Accounts
        }

        var ipfsimghashget=await ipfsImageHashGet(senddata);
        if(ipfsimghashget.data!==undefined){
          if(ipfsimghashget.data.ipfsval!=""){
          setIpfsHash(ipfsimghashget.data.ipfsval)
          setipfshashurl(`${config.IPFS_IMG}/${ipfsimghashget.data.ipfsval}`)
          }
          var newobj = {
            name: TokenName,
            tokenId:tokenid,
            image: config.IPFS_IMG + "/" + ipfsimghashget.data.ipfsval,
          }
          var ipfsmetatagVal = await ipfsmetadatafunciton(newobj);
          if (ipfsmetatagVal.data !== undefined) {
            if (ipfsmetatagVal.data.ipfsval != "") {
              var ipfsurl=ipfsmetatagVal.data.ipfsval
              set_ipfsmetatag(`${config.IPFS_IMG}/${ipfsurl}`)
            }
          }
        }
  
        toast.success("Approve Successfully", toasterOption);
        setApproveCallStatus('done');
      })
      .catch((error) => {
        toast.error("Approve failed", toasterOption);
        setApproveCallStatus('tryagain');
      })
    }
    function convert(n) {
      try {
        var sign = +n < 0 ? "-" : "",
          toStr = n.toString();
        if (!/e/i.test(toStr)) {
          return n;
        }
        var [lead, decimal, pow] = n.toString()
          .replace(/^-/, "")
          .replace(/^([0-9]+)(e.*)/, "$1.$2")
          .split(/e|\./);
        return +pow < 0
          ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
          : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
      } catch (err) {
        return 0;
      }
    
    }
    async function MintCall() {
 
     if (!Provider) {
       toast.warning("OOPS!..connect Your Wallet", toasterOption);
       return false;
     }
     var web3 = new Web3(Provider);
     let result = await web3.eth.getAccounts();
     var currAddr = result[0];
     var CurrAddr = result[0];
    //  var currAddr = window.web3.eth.defaultAccount;
    //  var CurrAddr = window.web3.eth.defaultAccount;
     if (!currAddr) {
       toast.warning("OOPS!..connect Your Wallet", toasterOption);
       return false;
     }
 
     const singleContract = new web3.eth.Contract(BEP721, ContractAddressUser);
     const CoursetroContract = singleContract
 
     const Digits = config.decimalvalues;
     let price = convert(TokenPrice*Digits);
     const TokenPriceInStr = price.toString();
     setMintCallStatus('processing');
   await CoursetroContract.methods.mint(
     TokenCount,
     TokenName,
     ipfshashurl,
     ipfsmetatag,
     TokenPriceInStr,
     TokenQuantity,
     TokenRoyalities
   )
   .send({ from: Accounts })
   .then(async (result) => {
     var transactionHash = '';
     if(result.transactionHash) {
       transactionHash = result.transactionHash;
     }
     else {
       transactionHash = result;
     }
     Set_MintHashVal(transactionHash);
       toast.success("Your Token Added Successfully", toasterOption);
         var Status = "true";
         var TokenAddItemPayload = {
           Image: TokenFile,
           ipfsimage:ipfshash,
           Name: TokenName,
           Count: TokenCount,
           Description: TokenDescription,
           Price: TokenPrice,
           Royalities: TokenRoyalities,
           Category_label: TokenCategory,
           Bid: TokenBid,
           Properties: TokenProperties,
           Owner: CurrAddr,
           Creator: CurrAddr,
           CategoryId: TokenCategory,
           Quantity: TokenQuantity,
           Balance: TokenQuantity,
           ContractAddress: ContractAddressUser,
           Status: Status,
           HashValue: transactionHash,
           Type: CollectibleType,
           MinimumBid: 0,
           Clocktime: '',
           EndClocktime: '',
           UnLockcontent: '',
           PutOnSale:PutOnSale,
           PutOnSaleType:PutOnSaleType, 
         };
         if(Unlockoncepurchased == true) {
           TokenAddItemPayload.UnLockcontent = UnLockcontent;
         }
 
         if(PutOnSale==true) {
           if(PutOnSaleType == 'FixedPrice') {
             TokenAddItemPayload.Price = TokenPrice;
             if (biddingtoken==config.currencySymbol) { //BNB
              TokenAddItemPayload.biddingtoken=config.currencySymbol
              }else{
              TokenAddItemPayload.biddingtoken=biddingtoken;
              }
           }
           else if(PutOnSaleType == 'TimedAuction') {
             TokenAddItemPayload.MinimumBid = MinimumBid;
             TokenAddItemPayload.Clocktime = Clocktime;
             TokenAddItemPayload.EndClocktime = EndClocktime;
             if (biddingtoken==config.currencySymbol) {
              TokenAddItemPayload.biddingtoken=config.tokenSymbol
              }else{
              TokenAddItemPayload.biddingtoken=biddingtoken;
              }
             TokenAddItemPayload.Price = 0;
           }else{
            TokenAddItemPayload.PutOnSaleType=PutOnSaleType;
            if (biddingtoken==config.currencySymbol) {
              TokenAddItemPayload.biddingtoken=config.tokenSymbol
              }else{
              TokenAddItemPayload.biddingtoken=biddingtoken;
              }
          }
         }
         await TokenAddItemAction(TokenAddItemPayload);
         var TokenAddOwnerPayload = {
           'Count': TokenCount,
           'Price': TokenPrice,
           'Owner': CurrAddr,
           'Balance': TokenQuantity,
           'Quantity': TokenQuantity,
           'ContractAddress': ContractAddressUser,
           'Type': CollectibleType,
           HashValue: transactionHash,
           Status: Status,
         };
         if(PutOnSaleType == 'FixedPrice') {
          TokenAddOwnerPayload.Price = TokenPrice;
          if (biddingtoken==config.currencySymbol) {
            TokenAddOwnerPayload.biddingtoken=config.currencySymbol
          }else{
            TokenAddOwnerPayload.biddingtoken=biddingtoken;
        }        
      }
        if(PutOnSaleType == 'TimedAuction') {
          TokenAddItemPayload.MinimumBid = MinimumBid;
          if (biddingtoken==config.currencySymbol) {
            TokenAddOwnerPayload.biddingtoken=config.tokenSymbol
          }else{
            TokenAddOwnerPayload.biddingtoken=biddingtoken;
        }
      }
      if(PutOnSaleType == 'UnLimitedAuction') {
        if (biddingtoken==config.currencySymbol) {
          TokenAddOwnerPayload.biddingtoken=config.tokenSymbol
          }else{
            TokenAddOwnerPayload.biddingtoken=biddingtoken;
          }
      }
         await TokenAddOwnerAction(TokenAddOwnerPayload);
         setMintCallStatus('done');
       // })
     // }, 1500);
   })
   .catch((error) => {
     toast.error("Mint Failed", toasterOption);
     setMintCallStatus('tryagain');
   })
  
    
   }
    async function SignCall() {
      if (!Provider) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
      }
      var web3 = new Web3(Provider);
      var currAddr = Accounts;
      if (!currAddr) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
      }
  
      setSignCallStatus('processing');
      web3.eth.personal.sign("Sign Sell Order", currAddr)
      .then(async (result) => {
        toast.success("Sign Successfully", toasterOption);
        setSignCallStatus('done');
      })
      .catch(() => {
        toast.error("Sign Failed", toasterOption);
        setSignCallStatus('tryagain');
      })
    }
  
    async function SignLockCall() {
      if (!Provider) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
      }
      var web3 = new Web3(Provider);
      var currAddr = Accounts;
      if (!currAddr) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
      }
  
      setSignLockCallStatus('processing');
      web3.eth.personal.sign("Sign Lock Order", currAddr)
      .then(async (result) => {
        toast.success("Sign Lock Order Successfully", toasterOption);
        setSignLockCallStatus('done');
        setTimeout(() => window.$('#create_item_modal').modal('hide'), 600);
        setTimeout(() => history.push("/nft"), 1200);
      })
      .catch(() => {
        toast.error("Sign Failed", toasterOption);
        setSignLockCallStatus('tryagain');
      })
    }
  
    async function CreateCollectionValidation(chk){
      if (chk) {
        var ValidateError = {};
        if(CollectionFilePreUrl == '') {
          ValidateError.CollectionFilePreUrl = '"File" is required';
        }
        if(Dispname == '') {
          ValidateError.Dispname = '"Display Name" is not allowed to be empty';
        }
        if(Symbol == '') {
          ValidateError.Symbol = '"Symbol" is not allowed to be empty';
        }
        setValidateError(ValidateError);
          return ValidateError;
      }else {
        return {};
      }
    }
  
    async function CreateItemValidation(chk) {
      if(chk) {
        var ValidateError = {};
        if(PutOnSaleType=="TimedAuction") {
          var currDate = new Date();
          var sDate = new Date(Clocktime)
          if(StartDate=="Right after listing"){
            set_Clocktime(currDate);
            sDate = currDate;
          }
  
          var startDate = new Date(Clocktime)
          var eDate = new Date(EndClocktime);
          startDate.setSeconds(0);
          eDate.setSeconds(0);
          if(new Date(currDate) > new Date(sDate)){
            ValidateError.StartDate = "Start time must be greater than now";
          } 
    
          if(Date.parse(startDate) >= Date.parse(eDate)){
            ValidateError.EndDate = '"End date" & Time must be greater than start date and Time';
          }
        }
      
        if(TokenName == '') {
          ValidateError.TokenName = '"Name" is not allowed to be empty';
        }
  
        if(TokenRoyalities == '') {
          ValidateError.TokenRoyalities = '"Royalties" is not allowed to be empty';
        }
        else if(isNaN(TokenRoyalities) == true) {
          ValidateError.TokenRoyalities = '"Royalties" must be a number';
        }
        else if(TokenRoyalities > 50) {
          ValidateError.TokenRoyalities = '"Royalties" must be less than or equal to 50';
        }
        if(TokenFilePreUrl == '') {
          ValidateError.TokenFilePreUrl = '"File" is required';
        }
  
        if(Unlockoncepurchased && UnLockcontent == '') {
          ValidateError.UnLockcontent = '"Locked content" is required';
        }
  
        if(PutOnSale == true && PutOnSaleType=='FixedPrice') {
          if(TokenPrice == '' || isNaN(TokenPrice) == true && TokenPrice == 0) {
            ValidateError.TokenPrice = '"Price" must be a number';
          }
          else if(TokenPrice == 0) {
            ValidateError.TokenPrice = '"Price" must be greater than zero';
          }
        }
        if(biddingtoken==''){
         ValidateError.biddingtoken = '"Currency" Should be Selected';
 
       }
        if(PutOnSale == true && PutOnSaleType=='TimedAuction') {
          if(MinimumBid == '') {
            ValidateError.MinimumBid = '"Bid Price" must be a number';
          }
        }
        if(typeof TokenCategory == 'undefined' || TokenCategory == '') {
          ValidateError.TokenCategory = '"Category" is required';
        }
  
        if(TokenQuantity == '' || isNaN(TokenQuantity) == true) {
          ValidateError.TokenQuantity = '"Number of copies" must be a number & greater than zero';
        }
        else if(TokenQuantity == 0) {
          ValidateError.TokenQuantity = '"Number of copies" must be greater than zero';
        } 
        if(Unlockoncepurchased == true && UnLockcontent!=null && UnLockcontent!=undefined && UnLockcontent!=""){

        }else if(Unlockoncepurchased == true ){
          ValidateError.UnLockcontent = 'Unlock content must be a Valid one';
        }
        setValidateError(ValidateError);
        return ValidateError;
      }
      else {
        return {};
      }
    }
  
    async function DateChange(from, val) {
      if(from == 'StartDateDropDown') {
        if(val == 'PickStartDate') {
          ModalAction('calendar_modal','show');
        }
        else {
          Set_StartDate('Right after listing');
        }
      }
      else if(from == 'EndDateDropDown') {
        if(val == 'PickEndDate') {
          ModalAction('calendar_modal_expire','show');
        }
        else {
          Set_EndDate(val);
          var myDate = new Date();
          var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
          var newdate = new Date(newdat)
          set_EndClocktime(newdate)
        }
      }
    }
  
    async function ModalAction(id, type) {
      window.$('#'+id).modal(type);
      if(id=='calendar_modal') {
        if(Clocktime) {
          var dt = new Date(Clocktime);
          dt.setSeconds(0);
          var dt1 = dt.toLocaleString();
          Set_StartDate(dt1);
        }
      }
      else if(id=='calendar_modal_expire') {
        if(EndClocktime) {
          var dt = new Date(EndClocktime);
          dt.setSeconds(0);
          var dt1 = dt.toLocaleString();
          Set_EndDate(dt1);
        }
      }
    }
    
    async function CreateItem() {
      Set_FormSubmitUserClicked(true);
      var mydata = await getmylog();
      console.log(mydata.provider,'provider');
      setProvider(mydata.provider);
      var connectwallet=localStorage.getItem("tfnukukpannft");
      if(!connectwallet){
          toast.error("Please connect to a Metamask wallet", toasterOption);
          return false;
      }
      var errors = await CreateItemValidation(true);
      console.log("Form validation error",errors)
      var errorsSize = Object.keys(errors).length;
  
      if(errorsSize != 0) {
        toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
        return false
      }
      else if (mydata.provider) {
        if (typeof mydata.provider == 'undefined') {
          toast.error("Please Connect to Binance Network innor", toasterOption)
          Set_WalletConnected(false);
        }
        else {
          var web3 = new Web3(mydata.provider);
          // var currAddr = web3.eth.getAccounts();
          web3.eth.getBalance(mydata.address, async (errors, balance) => {
            var usercurbal = 0;
            if (typeof balance == 'undefined' || balance == null) {
              Set_UserAccountBal(0);
            }
            else {
              usercurbal = balance/config.decimalvalues;
              Set_UserAccountBal(usercurbal);
            }
            if(usercurbal == 0){
              toast.error("Insufficient balance", toasterOption);
              return false;
            }
            var TokenCategory_label = TokenCategory;
  
            let payload = {
              TokenName,
              TokenRoyalities,
              image: TokenFile,
              TokenCategory_label,
              PutOnSaleType,
              TokenPrice,
              biddingtoken
            }

            const resp = await CreateTokenValidationAction(payload);
            if(resp && resp.data) {
              if(resp.data.errors) {
                var errors = resp.data.errors;
                var errorsSize = Object.keys(errors).length;
                if(errorsSize != 0) {
                  setValidateError(errors);
                  toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
                }
                else {
                  setValidateError({});
                  window.$('#create_item_modal').modal('show');
                }
              }
            }
          })
        }
      }
      else {
        toast.error("Please Connect to Binance Network outer", toasterOption);
        Set_WalletConnected(false);
      }
    }
    const SelectBidcurrency = (e) => {
      set_biddingtoken(e.target.value)
      youWillPay(e.target.value, TokenPrice)
    }
    async function youWillPay(bid, amount) {
      var bidper;
      var provider = window.ethereum;
       var web3 = new Web3(provider);
      if(location_pathname=="create-single"){
        var nftContract = new web3.eth.Contract(BEP721,ContractAddressUser);
        if(bid==config.currencySymbol){
          var fee = await nftContract.methods.getServiceFee().call();
        } else if(bid==config.tokenSymbol){
          var fee = await nftContract.methods.getKukuServiceFee().call();
        }else{
          var fee = await nftContract.methods.getServiceFee().call();
        }
       }
       bidper = fee/config.decimalvalues
      setservicefee(bidper)
      var youwill = (bidper / 100) * amount
      var finalwill = amount - youwill
      setTokenWill(finalwill)
    }
    const AfterWalletConnected = async () => {
      var currAddr = await localStorage.getItem("address");
      if(currAddr){
        GetCategoryCall();
        TokenCount_Get_Call();
      }
    }
  
    async function TokenCount_Get_Call() {
      var Resp = await TokenCount_Get_Action();
      if(Resp && Resp.data && Resp.data.tokenId) {
      }
    }
  
    var dateTimeValidation = (currentDate) => {
      return currentDate.isAfter(moment(new Date()));
    };    
    return (
      <div className={classes.root}>
        <ConnectWallet
          Set_UserAccountAddr={Set_UserAccountAddr}
          Set_UserAccountBal={Set_UserAccountBal}
          Set_WalletConnected={Set_WalletConnected}
          Set_AddressUserDetails={Set_AddressUserDetails}
          Set_Accounts={Set_Accounts}
          WalletConnected={WalletConnected}
          AfterWalletConnected={AfterWalletConnected}
      />
        <ScrollToTopOnMount/>
        <CssBaseline />
        
        {/* Header APP */}
        <HeaderDashboard 
          classes={classes}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          handleMenu={handleMenu}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
        
       {/*  side Menu */}
        <SidebarLinksAfterlogin classes1={classes} open={open}/>
        <div className="overlay"></div>
        <main className={classes.content + " dashContent"}>
          <div className={classes.toolbar} />
          <div className="dashPages">              
            <GridContainer>              
              <GridItem xs={12} sm={12} md={9} className="m-auto">
                <div class="d-flex align-items-center">
                  <Link class="goback_link" to="/nft">
                    <i class="bi bi-chevron-left"></i><span>Go back</span>
                  </Link>
                </div>
                <div>
                {(CollectibleType == 721) ?
                  <h2 className="inner_title text-center">Create NFT</h2>
                 :
                 <h2 className="inner_title text-center">Create Multiple NFT</h2>
                }
                 <div className="container">
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                      <label className="primary_label">Preview</label>
                        <div className="single_collectible">
                          <div className="item">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="masonry_likes">
                                <i className="fas fa-heart mr-2"></i>
                                <span>0</span>
                              </div>                              
                            </div>
                            
                            {
                                (TokenFilePreUrl.split('.').pop() == 'mp4')
                                ?
                                  <video id="imgPreview" src={TokenFilePreReader != "" ?
                                   TokenFilePreReader : `${config.Back_URL}/images/noimage.png`} type='video/mp4'
                                    alt="Collection" className="img-fluid" autoPlay playsInLine loop muted>
                                    </video>
                                :
                                <div className="text-center">
                                 {(TokenFilePreUrl.split('.').pop() == 'mp3') ?
                                <div>
                                <img src={config.Back_URL+"/images/music.6bbb3af3.png"} id="imgPreview" alt="Collections" className="img-fluid" />
                                <audio src={TokenFilePreReader != "" ? TokenFilePreReader : `${config.Back_URL}/images/noimage.png`} type="audio/mp3" controls className="audio home">
                                  </audio>
                                </div>
                                :
                                  <img src={TokenFilePreReader != "" ? TokenFilePreReader : `${config.Back_URL}/images/noimage.png`} id="imgPreview" alt="Collections" className="img-fluid" />
                                }
                                </div>
                              }
                          <h2>{TokenName}</h2>
                            <div className="d-flex justify-content-between align-items-end">
                              <div>
                                <h3>
                                {(PutOnSale == true && PutOnSaleType=='FixedPrice') &&
                                  <span>{TokenPrice} {biddingtoken}   </span>
                                }
                                {(PutOnSale == true && PutOnSaleType=='TimedAuction') &&
                                  <span>{MinimumBid} {biddingtoken}   </span>
                                }
                                   {/* {TokenQuantity} of {TokenQuantity} */}
                                </h3>
                                <h4> 
                                  {(PutOnSale == false || (PutOnSale == true && PutOnSaleType=='FixedPrice' && 
                                    TokenPrice == 0)) && 
                                    <span>Not for sale </span>
                                  }
                                  {( (PutOnSale == true && PutOnSaleType=='FixedPrice' && TokenPrice > 0)) &&
                                    <span>Place a Bid</span>
                                  } 
                                    {(PutOnSale == true && PutOnSaleType=='TimedAuction') && <span>Minimum Bid </span>}
                                    {(PutOnSale == true && PutOnSaleType=='UnLimitedAuction') && <span>Open for Bids </span>}
                              {(PutOnSale == true && !PutOnSaleType=='UnLimitedAuction') &&
                                <span>{usdval*TokenPrice} USD</span>
                              }
                                </h4>
                              
                              </div>
                              <div className="d-flex creators_details">
                                <img src={require("../assets/images/user_01.png")} alt="User" className="img-fluid" />
                                <img src={require("../assets/images/user_02.png")} alt="User" className="img-fluid" />
                                <img src={require("../assets/images/user_03.png")} alt="User" className="img-fluid" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <form>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <div className="d-flex justify-content-between align-items-start">
                                  <div>
                                    <label className="primary_label" htmlFor="inputEmail4">Upload file</label>
                                    <p className="form_note">PNG, GIF, WEBP, MP4 or MP3. Max 30mb.</p>
                                    {ValidateError.TokenFilePreUrl && <span className="text-danger">{ValidateError.TokenFilePreUrl}</span>}
                                  </div>
                                  <div className="file_btn primary_btn">Upload
                                    <input className="inp_file" type="file" name="file" onChange={selectFileChange}/>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <div className="d-flex justify-content-between align-items-start">
                                <div>
                                  <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                                  <p className="form_note">You’ll receive bids on this item</p>
                                </div>
                                <label className="switch toggle_custom">
                                <input type="checkbox"
                                  id="putonsale"
                                  name="putonsale"
                                  onChange={CheckedChange}
                                  checked={PutOnSale}/>
                                  <span className="slider"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <div className="d-flex justify-content-between align-items-start">
                                <div>
                                  <label className="primary_label" htmlFor="inputEmail4">Unlock Once Purchased</label>
                                  <p className="form_note">Content will be unlocked after<br/> successful transaction</p>
                                </div>
                                <label className="switch toggle_custom">
                                <input type="checkbox"
                                  id="unlockoncepurchased"
                                  name="unlockoncepurchased"
                                  onChange={CheckedChange}
                                  />                                  
                                <span className="slider"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                         <div className="form-row">
                            <div className="form-group col-md-12">
                            {
                (PutOnSale==false)?(''):(
                              <div className="d-flex justify-content-start align-items-start">
                                <Button 
                                  onClick={()=>{changePutOnSaleType('FixedPrice')}} 
                                  className={"nft_create_btn mr-3 " + ((PutOnSaleType=='FixedPrice')?'active':'inactive')}
                                >
                                  Fixed Price
                                </Button>
                                {(CollectibleType == 721) &&
                                  <Button 
                                    onClick={()=>{changePutOnSaleType('TimedAuction')}}
                                    className={"nft_create_btn mr-3 " + ((PutOnSaleType=='TimedAuction')?'active':'inactive')}
                                  >
                                    Timed Auction
                                  </Button>
                                }
                                <Button
                                  onClick={()=>{changePutOnSaleType('UnLimitedAuction')}} 
                                  className={"nft_create_btn " + ((PutOnSaleType=='UnLimitedAuction')?'active':'inactive')}
                                >
                                  Ongoing Auction
                                </Button>
                              </div>
                )}
                            </div>
                          </div>
                          {
                         (Unlockoncepurchased) &&
                         <>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                            <input type="text" className="primary_inp" 
                                name="UnLockcontent"
                                id="UnLockcontent"
                                onChange={inputChange}
                                placeholder="Digital key,code to redeem or link to a file..."/>
                              <p className="form_note">Tip: Markdown syntax is supported</p>
                            </div>
                          </div>
                        {ValidateError.UnLockcontent && <span className="text-danger">{ValidateError.UnLockcontent}</span>}
                        </>
                          }

                          {(PutOnSale==true && PutOnSaleType == 'FixedPrice')&&
                         <div className="form-row">
                         <div className="form-group col-md-6">
                           <label className="primary_label" htmlFor="name">Price</label>
                           <input type="number" 
                           onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
                           className="primary_inp" autoComplete="off" id="TokenPrice" name="TokenPrice" onChange={inputChange}
                           placeholder="Enter the price of one piece" min="0"/>
                                 {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                         </div>
                         <div className="form-group col-md-6">
                           <label className="primary_label">&nbsp;</label>
                         <select class="d-block custom-select select_menu primary_inp w-100" id="TokenCategory1" name="TokenCategory" onChange={SelectBidcurrency}  >
                                   {
                                     price.map((item) => {
                                       return (
                                       <option  value={item.label} >{item.label}</option>
                                       )
                                     })
                                   }
                             </select>  
                             {ValidateError.biddingtoken && <span className="text-danger">{ValidateError.biddingtoken}</span>}
                           </div>
                         <div className="inbut_reason_tetx">
                    
                       <p>Service fee <span>{servicefee} %</span></p>
                     
                     <p>You will receive  <span>{tokenWill} {biddingtoken}</span></p>
                   </div>
                       </div>
                        }
                        {(PutOnSale==true && PutOnSaleType == 'TimedAuction')&&
                            <div className="form-row">
                            <div className="form-group col-md-6">
                              <label className="primary_label" htmlFor="name">Minimum bid</label>
                              <input type="number" onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} className="primary_inp" autoComplete="off" id="MinimumBid" name="MinimumBid" onChange={inputChange}
                               placeholder="Enter minimum bid" min="0"/>
                      {ValidateError.MinimumBid && <span className="text-danger">{ValidateError.MinimumBid}</span>}
                            </div>
                            <div className="form-group col-md-6">
                              <label className="primary_label">&nbsp;</label>
                            <select class="d-block custom-select select_menu primary_inp w-100" id="TokenCategory1" name="TokenCategory" onChange={SelectBidcurrency}  >
                                  {
                                   bidprice.map((item) => {
                                     return (
                                     <option  value={item.label} >{item.label}</option>
                                     )
                                   })
                                 }
                                </select>  
                                {ValidateError.biddingtoken && <span className="text-danger">{ValidateError.biddingtoken}</span>}
                              </div>
                            </div>
                          }
                    {(PutOnSale==true && PutOnSaleType == 'TimedAuction')&&
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label className="primary_label" htmlFor="minimumbid">Starting Date</label>
                                  <div class="dropdown" style={{background: "#efefef"}}>
                                      <button class="btn btn-secondary dropdown-toggle filter_btn m-0 text-left w-100 date_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span id="menuBut">{StartDate}</span>
                                      </button>
                                      <div class="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                        <div id="RightAfterListing" onClick={()=>{DateChange('StartDateDropDown','RightAfterListing')}} >Right after listing</div>
                                        <div id="PickStart" onClick={()=>{DateChange('StartDateDropDown','PickStartDate')}} >Pick specific date</div>
                                      </div>
                                    </div>
                                    {ValidateError.StartDate && <span className="text-danger">{ValidateError.StartDate}</span>}
                                </div>
                                <div className="form-group col-md-6">
                                  <label className="primary_label" htmlFor="minimumbid">Expiration Date</label>
                                  <div class="dropdown" style={{background: "#efefef"}}>
                                <button class="btn btn-secondary dropdown-toggle filter_btn m-0 text-left w-100 date_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <span id="menuBut">{EndDate}</span>
                                </button>
                                <div class="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                  <div onClick={()=>{DateChange('EndDateDropDown','1 Day')}} >1 Day</div>
                                  <div onClick={()=>{DateChange('EndDateDropDown','3 Day')}} >3 Day</div>
                                  <div onClick={()=>{DateChange('EndDateDropDown','5 Day')}} >5 Day</div>
                                  <div onClick={()=>{DateChange('EndDateDropDown','7 Day')}} >7 Day</div>
                                  <div onClick={()=>{DateChange('EndDateDropDown','PickEndDate')}} >Pick specific date</div>
                                  
                                </div>
                                </div>
                                {ValidateError.EndDate && <span className="text-danger">{ValidateError.EndDate}</span>}

                                </div>
                              </div>
                            </div>
                          </div>
                        }

<div class="modal fade primary_modal" id="calendar_modal" tabindex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                      <div class="modal-content">
                        <div class="modal-module">
                          <div class="modal-header text-center">
                            <h5 class="modal-title" id="calendar_modalLabel">Choose date</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="cancelcalender">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div className="pb-3">
                              <Datetime
                                minDate={new Date().getTime()}
                                input={false}
                                value={Clocktime}
                                onChange={(value) => set_Clocktime(value)}
                              />
                            </div>
                          </div>
                          <div class="text-center mb-3">
                            <Button className="primary_btn" id="doneStartDate" onClick={() => ModalAction('calendar_modal','hide')}>Done</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal fade primary_modal" id="calendar_modal_expire" tabindex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                      <div class="modal-content">
                        <div class="modal-module">
                          <div class="modal-header text-center">
                            <h5 class="modal-title" id="calendar_modalLabel">Choose date</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="cancelcalender">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div className="pb-3">
                              <Datetime
                                input={false}
                                value={EndClocktime}
                                minDate={new Date().getTime()}
                                onChange={(value) => set_EndClocktime(value)}
                              />
                            </div>
                          </div>
                          <div class="text-center mb-3">
                            <Button className="primary_btn" id="doneEndDate" onClick={() => ModalAction('calendar_modal_expire','hide')}>Done</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="name">Name</label>
                              <input type="text" className="primary_inp" id="TokenName"
                                name="TokenName" onChange={inputChange}
                                placeholder="e.g. Redeemable"  autoComplete="off"
                                />                            
                              </div>
                              {ValidateError.TokenName && <span className="text-danger">{ValidateError.TokenName}</span>}
                          </div>
                          <div  className="form-row">
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="desccription">Description <span className="text-muted">(Optional)</span></label>
                              <input type="text" 
                              className="primary_inp"
                              id="TokenDescription"
                              name="TokenDescription" 
                              autoComplete="off"
                              onChange={inputChange} placeholder="e.g. After purchasing..."/>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="name">Royalties</label>
                              <div className="inp_grp">
                              <input type="number" className="primary_inp"
                                 id="TokenRoyalities" name="TokenRoyalities" onKeyDown={(evt) => ["e", "E", "+", "-",".",","].includes(evt.key) && evt.preventDefault()}
                                 onChange={inputChange} autoComplete="off" placeholder="1" max="50" min="0" value={TokenRoyalities}/>
                              <p className="inp_append new_inut_ccc">%</p>
                              <span>Suggested: 0%, 10%, 20%, 30%. Maximum is 50%</span>

                              </div>
                              {ValidateError.TokenRoyalities && <span className="text-danger"><br/>{ValidateError.TokenRoyalities}</span>}
                            </div>                           
                          </div>
                          <div className="form-row">                            
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="desccription">Properties <span className="text-muted">(Optional)</span></label>
                              <input type="text" className="primary_inp" 
                              id="TokenProperties" 
                              name="TokenProperties" 
                              onChange={inputChange}
                              placeholder="e.g. size"
                              autoComplete="off"/>                           
                            </div>
                          </div>
                          <div className="form-row">                            
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="desccription">Category</label><span className="text-muted"></span>
                              <select class="custom-select select_menu primary_inp" id="TokenCategory" name="TokenCategory" onChange={handleChange}  >
                            <option value="" >Select Category </option>
                                {
                                  Categorylist.map((item) => {
                                    return (
                                    <option  value={item._id} >{item.name}</option>
                                    )
                                  })
                                }
                          </select>  
                    {ValidateError.TokenCategory && <span className="text-danger"><br/>{ValidateError.TokenCategory}</span>}

                            </div>
                          </div>
                          <div>
                            <Button type="button" className="primary_btn" 
                            onClick={CreateItem}
                            >Create item</Button>
                          </div>
                        </form>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </GridItem>             
            </GridContainer>
          </div>
        </main>
        
        <WalletModal/>       

      {/* Choose Collection Modal */}
      <div class="modal fade primary_modal" data-backdrop="static" id="choose_collection_modal" tabindex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="choose_collection_modalLabel">Collection</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="d-flex align-items-center">
                <div class="hex center">
                  <div class="hex__shape">
                    <div class="hex__shape">
                      <div class="hex__shape">
                      <img src={require("../assets/images/img_02.jpg")} alt="logo" className="img-fluid"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_16 font_600 line_20 mb-1">We recommend an image of at least 400x400. Gifs work too.</p>
                  <div className="file_btn primary_btn d-inline-block">Choose File
                    <input className="inp_file" type="file" name="file"/>
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Display Name <span className="text-muted">(30 available)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Enter token name"/>
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Symbol <span className="text-muted">(required)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription" placeholder="Enter token symbol"/>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Spread some words about token collection"/>                  
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Short url</label>
                    <input type="text" className="form-control primary_inp" id="desccription" defaultValue="spideybarter.io/"/>
                  </div>
                </div>
                <div className="text-center">
                  <Button className="create_btn">Create Collection</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Create Item Modal */}
      <div class="modal fade primary_modal" data-backdrop="static" id="create_item_modal" tabindex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="create_item_modalLabel">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">                         
              <form> 
              <div className="media approve_media">
              {ApproveCallStatus=='processing' ? 
              <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
              :
              ApproveCallStatus=="done"?
              <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
              :
              <i className="fas fa-check mr-3" aria-hidden="true"></i>
              }
              <div className="media-body">
                <p className="mt-0 approve_text">Approve</p> 
                <p className="mt-0 approve_desc">Approve performing transactions with your wallet</p>
              </div>
            </div>
              <div className="text-center my-3">
              <Button
                    className="btn_outline_red btn-block"
                    disabled={(ApproveCallStatus=='processing' || ApproveCallStatus=='done')}
                    onClick={ApproveCall}
                  >
                    {ApproveCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {ApproveCallStatus == 'init' && 'Start'}
                    {ApproveCallStatus == 'processing' && 'In-progress...'}
                    {ApproveCallStatus == 'done' && 'Done'}
                    {ApproveCallStatus == 'tryagain' && 'Try Again'}
                  </Button> 
              </div>
              <div className="media approve_media">
              {MintCallStatus=='processing' ?
                <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
              :
              MintCallStatus=='done'?
              <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
              :
              <i className="fas fa-check mr-3" aria-hidden="true"></i>
              }
              <div className="media-body">
                <p className="mt-0 approve_text">Upload files & Mint token</p> 
                <p className="mt-0 approve_desc">Call contract method</p>
              </div>
            </div>
              <div className="text-center my-3">
              <Button
                    className="btn_outline_red btn-block" 
                    disabled={(ApproveCallStatus!='done' || MintCallStatus=='processing' || MintCallStatus=='done')}
                    onClick={MintCall}
                  >
                    {MintCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {MintCallStatus == 'init' && 'Start'}
                    {MintCallStatus == 'processing' && 'In-progress...'}
                    {MintCallStatus == 'done' && 'Done'}
                    {MintCallStatus == 'tryagain' && 'Try Again'}
                  </Button>
              </div>

              <div className="media approve_media">
              {SignCallStatus=='processing' ?
              <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
              :
              SignCallStatus=='done'?
              <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
              :
              <i className="fas fa-check mr-3" aria-hidden="true"></i>
              }
              <div className="media-body">
                <p className="mt-0 approve_text">Sign sell order</p> 
                <p className="mt-0 approve_desc">Sign sell order using your wallet</p>
              </div>
            </div>
              <div className="text-center my-3">
              <Button
                    className="btn_outline_red btn-block"
                    disabled={(MintCallStatus!='done' || SignCallStatus=='processing' || SignCallStatus=='done')}
                    onClick={SignCall}
                  >
                    {SignCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {SignCallStatus == 'init' && 'Start'}
                    {SignCallStatus == 'processing' && 'In-progress...'}
                    {SignCallStatus == 'done' && 'Done'}
                    {SignCallStatus == 'tryagain' && 'Try Again'}
                  </Button>
              </div>

              <div className="media approve_media">
              {SignLockCallStatus=='processing' ? 
              <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
              :
              SignLockCallStatus=='done'?
              <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
              :
              <i className="fas fa-check mr-3" aria-hidden="true"></i>
            }
              <div className="media-body">
                <p className="mt-0 approve_text">Sign lock order</p> 
                <p className="mt-0 approve_desc">Sign lock order using your wallet</p>
              </div>
            </div>
              <div className="text-center mt-3">
              <Button
                    className="btn_outline_red btn-block"
                    disabled={(SignCallStatus!='done' || SignLockCallStatus=='processing' || SignLockCallStatus=='done')}
                    onClick={SignLockCall}
                  >
                    {SignLockCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {SignLockCallStatus == 'init' && 'Start'}
                    {SignLockCallStatus == 'processing' && 'In-progress...'}
                    {SignLockCallStatus == 'done' && 'Done'}
                    {SignLockCallStatus == 'tryagain' && 'Try Again'}
                  </Button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      </div>
    );
}

CreateSingle.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(CreateSingle);
