import React, { useState, useEffect } from "react";
import PropTypes, { func } from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";
import axios from "axios";
import config from "../lib/config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PankukuBarterABI from "../ABI/DETH.json";
import LotteryABI from "../ABI/Lottery.json";
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import moment from "moment";
import { getCurAddr } from "../actions/v1/User";
import Web3 from "web3";
import "@metamask/legacy-web3";
import ConnectWallet from "./separate/Connect-Wallet";
import { toast } from "react-toastify";
import {isEmpty} from '../helper/isEmpty';
import {validation} from '../helper/Validation';
import {addContactus} from '../actions/home';
toast.configure();
const drawerWidth = 240;
var randomstring = require("randomstring");
let toasterOption = config.toasterOption;
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7.5 + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  grow: {
    flexGrow: 1,
  },
});
const initialFormValue = {
  'email': '',
  'firstname':'',
  'lastname': '',
  'phone_number':'',
  'message': '',
  
}

const Contact = (props) => {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const { email, firstname, lastname,phone_number, message} = formValue;
    
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        
    }

    const handleSubmit = async (e) => {
       
      e.preventDefault();
      setLoader(true)
      let reqData = {
          firstname,
          lastname,
          email,
          phone_number,
          message,
          
      }
console.log("reqData---",reqData)
      let validationError = validation(reqData)
      if (!isEmpty(validationError)) {
          setValidateError(validationError)
          setLoader(false)
          return
      }
      try {
          const { status, loading} = await addContactus(reqData)
         // setLoader(loading)
            //  setEditForm(true)
              setFormValue(initialFormValue)
              toast('Send successfully',toasterOption);
      } catch (err) { 
        console.log(err)
      }
  }

  // const addContactus = async(data)=>{
  //   console.log(data,'data')
  // }

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(true);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
 
    const { classes, theme } = props;
  

  return (
    <div className={classes.root}>
      <ScrollToTopOnMount />
      <CssBaseline />
     
      {/* Header APP */}
      <HeaderDashboard
        classes={classes}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        handleMenu={handleMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />

      {/*  side Menu */}
      <SidebarLinksAfterlogin classes1={classes} open={open} />
      <div className="overlay"></div>
      <main className={classes.content + " dashContent"}>
        <div className={classes.toolbar} />
        <div className="dashPages">
           <GridContainer>
              <GridItem xs={12} sm={12} md={9} className="m-auto">
                <div className="aboutContent">
                 
       
      <GridContainer>
              <GridItem xs={12} sm={12} md={8}>

        <h2 class="abooutPageTitle">Contact Us</h2>
        <form action="">
          <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
              <div class="form-group">
                <input type="text" class="form-control contact_inp mt-2" 
                placeholder="First Name" 
                 name="firstname"
                 value={firstname}
                 onChange={handleChange}/>
                  {validateError.firstname && <p className="error-message">{validateError.firstname}</p>}
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div class="form-group">
                <input type="text" class="form-control contact_inp mt-2" placeholder="Last Name"
                name="lastname"
                value={lastname}
                onChange={handleChange}
                 />
                  {validateError.lastname && <p className="error-message">{validateError.lastname}</p>}
              </div>
            </GridItem>
           <GridItem xs={12} sm={12} md={6}>
              <div class="form-group">
                <input type="email" class="form-control contact_inp mt-2" placeholder="Email"
                 name="email"
                 value={email}
                 onChange={handleChange} />
                  {validateError.email && <p className="error-message">{validateError.email}</p>}
              </div>
            </GridItem>
           <GridItem xs={12} sm={12} md={6}>
              <div class="form-group">
                <input type="number" class="form-control contact_inp mt-2" placeholder="Phone Number"
                 name="phone_number"
                 value={phone_number}
                 onChange={handleChange} />
                  {validateError.phone_number && <p className="error-message">{validateError.phone_number}</p>}
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div class="form-group">
                <textarea class="form-control contact_inp" id="exampleFormControlTextarea1" placeholder="Message" rows="3"
                onChange={handleChange} name="message" value={message}
                ></textarea>
                 {validateError.message && <p className="error-message">{validateError.message}</p>}
              </div>
            </GridItem>
            
            <GridItem xs={12} sm={12} md={12}>
              <Button className="primary_btn mb-4" onClick={handleSubmit}>Submit</Button>
            </GridItem>
          </GridContainer>
        </form>
      </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        <div class="text-Yellow">
        <h2 class="aboutContentTitle mt-0">Contact Address</h2>

        <i class="fas fa-phone mt-3"></i> <a href="tel:+">+1 (276) 284-9879</a><br />
        <i className="fab fa-whatsapp"></i> <a href="https://wa.me/8707001">(+597) 8707001</a><br />
        <i class="fa fa-envelope mt-3"></i> <a href="mailto:">admin@pankuku.net</a><br />
        <i class="fas fa-globe mt-3"></i><b>panKUKU SURINAME</b>
        <ul><li>Dr. Axwijkstreet #50</li> 
        <li>Paramaribo </li>
        <li>Company Registration #64853</li></ul><br />
        <i class="fas fa-globe mt-3"></i><b>panKUKU LLC USA</b>
        <ul><li>30 N Gould St</li> 
        <li>Ste R</li>
        <li>Sheridan, WY 82801</li>
        <li>Filing ID 2022-001093100</li></ul><br />
        </div>
      
</GridItem>
   </GridContainer>
    
                </div>
              </GridItem>
          </GridContainer>
        </div>
      </main>

      <WalletModal />

     

     
      </div>
   
  );
};

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Contact);
