import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";

const drawerWidth = 240;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7.5 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  }
});

const Create =(props) => {
  const [open,setOpen] =useState(true)
   const [anchorEl,setAnchorEl] =useState(null)



  const handleDrawerOpen = () => {
    console.log("data---------------")
    setOpen(!open)
  };

  const handleDrawerClose = () => {
        setOpen(true)
  };

  const handleMenu = event => {
    console.log("----event",event)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
setAnchorEl(null);
  };

    const { classes, theme } = props;
    // const open = Boolean(anchorEl);    
    return (
      <div className={classes.root}>
        <ScrollToTopOnMount/>
        <CssBaseline />
        
        {/* Header APP */}
        <HeaderDashboard 
          classes={classes}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          handleMenu={handleMenu}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
        
       {/*  side Menu */}
        <SidebarLinksAfterlogin classes1={classes} open={open}/>
        <div className="overlay"></div>
        <main className={classes.content + " dashContent"}>
          <div className={classes.toolbar} />
          <div className="dashPages">              
            <GridContainer>              
              <GridItem xs={12} sm={12} md={12}>
                <div class="d-flex align-items-center">
                  <Link class="goback_link" to="/nft">
                    <i class="bi bi-chevron-left"></i><span>Go back</span>
                  </Link>
                </div>
                <div className="text-center">
                  <h2 className="inner_title">Create NFT</h2>
                  <p className="create_para">Choose “Single” if you want your NFT to be one of a kind or “Multiple” if you want to sell one NFT multiple times</p>
                  <div className="create_box_panel">
                    <Link to="/create-single" className="create_box">
                      <h2>Single</h2>
                      <img src={require("../assets/images/single_icon.png")} alt="logo" className="img-fluid"/>
                    </Link>
                    <Link to="/create-multiple" className="create_box">
                      <h2>Multiple</h2>
                      <img src={require("../assets/images/multiple_icon.png")} alt="logo" className="img-fluid"/>
                    </Link>
                  </div>
                  <p className="create_para mt-3">We do not own your private keys and cannot access your funds without your confirmation</p>
                </div>
              </GridItem>             
            </GridContainer>
          </div>
        </main>
        
        <WalletModal/>       

      </div>
    );
}

Create.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Create);
