import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import './index.css';
import './nft.css';
import EditProfile from "views/edit-profile";
import NFT from "views/nft";
import Create from "views/create";
import CreateSingle from "views/create-single";
import createMultiple from "views/create-multiple";
import Info from "views/info";
import createSingleMultiple from "views/create-single-multiple";
import Myitems from "views/my-items";
import Lottery from "views/lottery";
import About from "views/about";
import Contact from "views/contact";
import Privacy from "views/privacy";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
  <BrowserRouter basename="/">
    <ToastContainer />
    <CookiesProvider>
    <Switch>
      <Route path="/privacy" component={Privacy} />
      <Route path="/contact" component={Contact} />
      <Route path="/about" component={About} />
      <Route path="/lottery" component={Lottery} />
      <Route path="/edit-profile" component={EditProfile} />
      <Route path="/info/:tokenidval" component={Info} />
      <Route path="/create-multiple" component={createSingleMultiple} />
      <Route path="/create-single" component={createSingleMultiple} />
      <Route path="/create" component={Create} />
      <Route path="/my-items" component={Myitems} />
      <Route path="/user/:paramAddress" component={Myitems} />
      <Route path="/nft" component={NFT} /> 
      <Route exact path="/*" component={NFT}>
        <Redirect to="/nft" />
      </Route>     
    </Switch>
    </CookiesProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
