import React, { useState, useEffect } from "react";
import PropTypes, { func } from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";
import axios from "axios";
import config from "../lib/config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PankukuBarterABI from "../ABI/DETH.json";
import LotteryABI from "../ABI/Lottery.json";
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import moment from "moment";
import { getCurAddr } from "../actions/v1/User";
import Web3 from "web3";
import "@metamask/legacy-web3";
import ConnectWallet from "./separate/Connect-Wallet";
import { toast } from "react-toastify";
toast.configure();
const drawerWidth = 240;
var randomstring = require("randomstring");
let toasterOption = config.toasterOption;
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7.5 + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  grow: {
    flexGrow: 1,
  },
});

const About = (props) => {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(true);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
 
    const { classes, theme } = props;
  

  return (
    <div className={classes.root}>
      <ScrollToTopOnMount />
      <CssBaseline />
     
      {/* Header APP */}
      <HeaderDashboard
        classes={classes}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        handleMenu={handleMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />

      {/*  side Menu */}
      <SidebarLinksAfterlogin classes1={classes} open={open} />
      <div className="overlay"></div>
      <main className={classes.content + " dashContent"}>
        <div className={classes.toolbar} />
        <div className="dashPages">
           <GridContainer>
              <GridItem xs={12} sm={12} md={9} className="m-auto">
                <div className="aboutContent">
                 
        <h4 scale="xxl" className="abooutPageTitle">About Us</h4>
        <p mb="16px">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce viverra ultricies ante nec mattis. Quisque pulvinar sit amet risus eu feugiat. Proin ex ex, convallis a nibh et, sollicitudin varius ligula. Maecenas scelerisque nisl sit amet lacus porta ullamcorper. Morbi at mollis magna, quis consectetur erat. Sed pretium vel est sed malesuada. Suspendisse aliquet velit quis semper varius. Etiam in tellus ipsum. Cras aliquet non felis eget commodo. Cras lacinia urna et magna egestas, vitae convallis magna congue. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. </p>


<p mb="16px">Cras purus ante, auctor sed turpis at, malesuada lacinia sem. Nulla at mauris at arcu dapibus posuere ac interdum eros. Ut luctus ante ut congue efficitur. Nam libero tortor, mattis faucibus orci in, dignissim imperdiet ante. Curabitur dignissim a odio in ullamcorper. Sed odio diam, vestibulum in urna quis, pretium vestibulum diam. Donec a efficitur velit. Maecenas dignissim in nulla eu iaculis. Integer ut erat nec lacus ornare semper. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi at dolor lacus. Praesent eu dolor quis sem consequat facilisis tincidunt vel nulla. Curabitur ornare, est vitae ultrices dictum, libero tellus ultrices turpis, nec imperdiet nulla nulla nec velit. </p>
        

        <h4 scale="xl" className="aboutContentTitle">Pankuku- Fighting Cryptocurrency</h4>
        <p mb="16px">Praesent posuere massa orci, in eleifend elit sollicitudin convallis. In id laoreet mauris. Fusce at felis lobortis, elementum tortor sit amet, pellentesque nisl. Duis imperdiet sem non facilisis fringilla. Vestibulum vel tempor arcu. Morbi sollicitudin purus ut diam efficitur, ac euismod velit venenatis. Duis augue est, ultrices sit amet aliquet in, convallis eu velit. Sed at fringilla orci, at porta erat. Phasellus scelerisque, nisi nec ornare dictum, turpis magna bibendum diam, ac placerat ipsum metus vitae mauris. Nullam efficitur, augue ultrices dapibus pretium, sapien felis posuere nulla, non efficitur lorem diam at felis. </p>

    
                </div>
              </GridItem>
          </GridContainer>
        </div>
      </main>

      <WalletModal />

     

     
      </div>
   
  );
};

About.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(About);
