import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle
} from 'react';
import {
    useHistory
} from "react-router-dom";
import { Button, TextField } from '@material-ui/core';

import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import BEP721_ABI from '../../ABI/BEP721.json';
import KUKU_ABI from '../../ABI/KUKU.json';
import WBNB_ABI from '../../ABI/DETH.json';
import config from '../../lib/config';

import {
    AddLikeAction,
    GetLikeDataAction,
    TokenPriceChange_update_Action,
    PurchaseNow_Complete_Action,
    ActivitySection
} from '../../actions/v1/token';

import {
    getCurAddr,
    halfAddrShow
} from '../../actions/v1/User';
import {getmylog} from "../../helper/walletconnect"
import { toast } from 'react-toastify';
toast.configure();
let toasterOption       = config.toasterOption;
const contractAddr      = config.smartContract;



export const PurchaseNowRef = forwardRef((props, ref) => {

    const history = useHistory();

    var {
        UserAccountAddr,
        UserAccountBal,
        TokenBalance,
        MyItemAccountAddr,
        buytoken,
        AllowedQuantity,
        Set_AllowedQuantity
    } = props;

    const [ApproveCallStatus, setApproveCallStatus]   = useState('init');
    const [PurchaseCallStatus, setPurchaseCallStatus] = useState('init');
    const [price, Set_Price]                          = useState(0);
    const [PurchaseCurrency, setPurchaseCurrency]     = useState();
    const [BuyerName, Set_BuyerName]                = useState('');
    const [blns, Set_blns]                          = useState('');
    const [dethBln, Set_dethBln]                    = useState('');
    const [bidProfile1, Set_bidProfile1]            = useState([]);
    const [MultipleWei, Set_MultipleWei]            = useState(0);
    const [NoOfToken, Set_NoOfToken]                = useState(1);
    const [FormSubmitLoading, Set_FormSubmitLoading]= useState('');
    const [ValidateError, Set_ValidateError]        = useState({});
    const [YouWillPay, Set_YouWillPay]              = useState(0);
    const [TokenPrice, Set_TokenPrice]              = useState(0);
    const [MetaMaskAmt, setMetaMaskAmt]             = useState(0);
    const [servicefee, setservicefee]              = useState(0);
    const [saletokenbal, setSaletoken]              = useState(0);
    const [Provider,SetProvdier]                    = useState();
    const [KUKUContract, setKUKUContract]           = useState();
    const [BEP721Contract, setBEP721Contract]       = useState();
    const [WBNBContract, setWBNBContract]       = useState();

    var feepercval = 0;
    var biddingtoken = ""
    var PurchaseBalancenew =0
    if(buytoken!=undefined){
        feepercval = buytoken!==undefined && buytoken == config.currencySymbol ? 
         servicefee 
        :
        buytoken!==undefined && buytoken == config.tokenSymbol?
         servicefee: servicefee;
    }
    else {
        feepercval = servicefee;
    }
    const [feeperc, set_feeperc]                    = useState(feepercval)
    const [biddingtokenaddress,set_biddingtokenaddress]=useState(biddingtoken);
    const [PurchaseBalance,set_PurchaseBalance]        =useState(PurchaseBalancenew);

    async function buymodal(){
        setTimeout(() => window.$('#PurchaseNow_modal').modal('hide'), 600);
        setTimeout(() => window.location.reload(false), 900);
    }
    const PriceCalculate = async (data={}) => {
        try{
            if (window.ethereum) {
             var web3 = new Web3(window.ethereum);
               var CoursetroContract = new web3.eth.Contract(BEP721_ABI, config.singleContract);
               if(data.PurchaseCurrency==config.currencySymbol){
                   var fee = await CoursetroContract.methods.getServiceFee().call();
                 } else if(data.PurchaseCurrency==config.tokenSymbol){
                   var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                 }else{
                   var fee = await CoursetroContract.methods.getServiceFee().call();
                 }
                 var price       = (typeof data.price != 'undefined') ? data.price : TokenPrice;
                 var quantity    = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
                 var newPrice    = price*config.decimalvalues
                 var per         = (newPrice * fee) / 1e20;
                 var mulWei      = newPrice + per;
                 Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                 Set_MultipleWei(mulWei);
                 Set_Price(newPrice)
           }
         }catch(err){
         }
           }
    const onKeyUp = (e) => {
        var charCode = e.keyCode;
        if((charCode>47 && charCode <58) || (charCode>95 && charCode <106)){
          var ValidateError = {};
          Set_ValidateError(ValidateError);
        }else{
          var ValidateError = {};
          ValidateError.NoOfToken = '"Quantity" must be a number';
          Set_NoOfToken("");
          Set_ValidateError(ValidateError);
        }
      }
      
    const inputChange = (e) => {
        if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
            var value = e.target.value;
            switch(e.target.name) {
                case 'NoOfToken':
                    Set_NoOfToken(value);
                    PriceCalculate({quantity:value,PurchaseCurrency:PurchaseCurrency});
                    break;
                case 'TokenPrice':
                    Set_TokenPrice(value);
                    if(value != '' && isNaN(value) == false && value > 0) {
                        PriceCalculate({price:value,PurchaseCurrency:PurchaseCurrency});
                    }
                    break;
                default:
            }
        }
    }
    const ItemValidation = async (data={}) => {
        var ValidateError = {};
console.log(data,'datadatadatadatadatadatadataWWW')
        var Chk_TokenPrice = (typeof data.TokenPrice!='undefined')?data.TokenPrice:TokenPrice;
        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
        console.log(Chk_TokenPrice,'Chk_TokenPrice')
        console.log(YouWillPay ,PurchaseBalance,'>>>>>>>>>>>++++')
        var Collectible_balance = 0;
    if (item && item.tokenowners_current && item.tokenowners_current.balance) {
      Collectible_balance = item.tokenowners_current.balance;
    }

        if(quantity == '') {
            ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
        }
        else if(quantity == 0) {
            ValidateError.NoOfToken = '"Quantity" must be greater than 0';;
        } 
        else if(isNaN(quantity) == true) {
          ValidateError.NoOfToken = '"Quantity" must be a number';
        }
        if (quantity > Collectible_balance) {
            ValidateError.NoOfToken =
              '"Quantity" must be below on ' + Collectible_balance;
          }
        if(Chk_TokenPrice == '') {
            ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
        }
        else if(Chk_TokenPrice == 0) {
            ValidateError.TokenPrice = '"Token Price" must be greater than 0';;
        } 
        else if(isNaN(Chk_TokenPrice) == true) {
          ValidateError.TokenPrice = '"Token Price" must be a number';
        }
        else if(YouWillPay > PurchaseBalance) {
            ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
        }
        else {
            await props.GetUserBal();
            if(YouWillPay > PurchaseBalance) {
                ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
            }
            else {
                delete ValidateError.TokenPrice;
            }
        }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }

    function convert(n){
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead,decimal,pow] = n.toString()
            .replace(/^-/,"")
            .replace(/^([0-9]+)(e.*)/,"$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }
   
    async function FormSubmit(){
        var errors = await ItemValidation();
        var errorsSize = Object.keys(errors).length;
        console.log("errors:",errors)
        if(errorsSize != 0) {
            toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
            return false;
        }
       
        var mydata = await getmylog();
        if(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!="") {
        //     SetProvdier(mydata.provider);
            const web3 =  new Web3(Provider);
            if(
                web3
            ) {
                if (PurchaseCurrency != config.currencySymbol) {
                    if (PurchaseCurrency == config.tokenSymbol) {
                        var KUKUContract       = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
                        var currAddr             = mydata.address;
                        var tokenBal             = await KUKUContract.methods.balanceOf(currAddr).call();
                        var tokenBalance          = tokenBal / config.decimalvalues
                        if (tokenBalance >= parseFloat(YouWillPay)) {
                            window.$('.modal').modal('hide');
                            window.$('#PurchaseStep_modal').modal('show');
                        } else {
                            toast.error("Insufficient balance", toasterOption);
                        }

                    }
                    else if (PurchaseCurrency == config.currencySymbol) {
                       
                        if (PurchaseBalance >= parseFloat(YouWillPay)) {
                            window.$('.modal').modal('hide');
                            window.$('#PurchaseStep_modal').modal('show');
                        } else {
                            toast.error("Insufficient balance", toasterOption);
                        }
                    }
                }
                else {
                    console.log("CoursetroContract0",item)
                    var tokenPrice = (item.tokenowners_current && item.tokenowners_current.tokenPrice) ? item.tokenowners_current.tokenPrice : 0

                    if (tokenPrice && tokenPrice > 0) {
                            if (PurchaseBalance==0) {
                                toast.error("Insufficient balance", toasterOption);
                                return false;
                            }
                    
                            if (YouWillPay>PurchaseBalance) {
                                toast.error("Insufficient balance", toasterOption);
                                return false;
                            }
                            var tokenContractAddress = item.contractAddress.toString();
                            var tokenType = item.type.toString();
                            var bal = parseInt(item.balance);
                            // var web3   = new Web3(mydata.provider);
        
                            const BEP721Contract    = new web3.eth.Contract(BEP721_ABI, item.contractAddress)
                            let mulamount =convert(item.tokenowners_current.tokenPrice * config.decimalvalues);
                            var sendAmount = mulamount.toString();
                            Set_FormSubmitLoading('processing');

                            var tokenaddress= PurchaseCurrency==config.currencySymbol?
                            config.singleContract
                            :
                            PurchaseCurrency==config.tokenSymbol?
                            config.PankukuAddr
                            :
                            "";
                            BEP721Contract.methods
                            .saleToken(
                                item.tokenowners_current.tokenOwner,
                                item.tokenCounts,
                                sendAmount,
                                tokenaddress
                            )
                            .send({
                                from: props.Accounts,
                                value: MultipleWei
                            })
                            .then(async (result) => {
                                Set_FormSubmitLoading('done');
                                console.log('result : ', result);
                                var postData = {
                                    tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                    UserAccountAddr: UserAccountAddr, // new owner
                                    tokenCounts: item.tokenCounts,
                                    tokenType: item.type,
                                    NoOfToken: item.type == 721 ? 1 : NoOfToken,
                                    transactionHash: result.transactionHash,
                                    PurchaseCurrency: PurchaseCurrency
                                }
                                var Resp = await PurchaseNow_Complete_Action(postData);
                                if (Resp.data && Resp.data.toast && Resp.data.toast.type=='success') {
                                    toast.success("Collectible purchase successfully", toasterOption)
                                    window.$('.PurchaseNow_modal').modal('hide');
                                    setTimeout(() => { history.push("/my-items") }, 2000);
                                }
                            })
                            .catch((error) => {
                                Set_FormSubmitLoading('error');
                                toast.error('Order not placed', toasterOption);
                            })
                        
                    }else{
                        toast.error('Oops something went wrong.!', toasterOption);
                    }
                }
            }
        }
    }
    async function FormSubmit_StepOne(){

        if(Provider) {
            console.log(Provider,'ProviderProvider')
            var web3 = new Web3(Provider)
            if(
                web3
                && web3.eth
            ) {
                setApproveCallStatus('processing');
                    var CoursetroContract = PurchaseCurrency == config.tokenSymbol ?
                                        KUKUContract 
                                        :
                                        PurchaseCurrency == config.currencySymbol ?
                                        BEP721Contract 
                                        :
                                        KUKUContract

                var currAddr           = await localStorage.getItem("address");
                console.log(currAddr,'address')
                console.log(props.Accounts,'adderess1',typeof props.Accounts)
                console.log(currAddr,config.singleContract,'>>>>>>>>>')
                var getAllowance       = await KUKUContract.methods.allowance(currAddr,config.singleContract).call();
                const Contractaddress  = item.contractAddress;
                var sendVal            = parseInt(MultipleWei)+parseInt(getAllowance);
                var sendVal = await convert(sendVal);
                console.log(Contractaddress,'contract');
                var loginAddr = await web3.utils.toChecksumAddress(props.Accounts);
                console.log(loginAddr,'loginAddrloginAddr')
                await CoursetroContract.methods
                    .approve(
                        Contractaddress,
                        sendVal.toString()
                    )
                    .send({
                        from: loginAddr,
                    })
                    .then(async (result) => {
                        setApproveCallStatus('done');
                       toast.success("Collectible purchase successfully", toasterOption)
                    })
                    .catch((error) => {
                        setApproveCallStatus('tryagain');
                        console.log(error,'errorerrorerrorerror',props.Accounts)
                        toast.error('Order not approved', toasterOption);
                    })
            }else{
                alert("something wrong")
            }
        }else{
            alert("something wrong")
        }
    }

    async function FormSubmit_StepTwo(){
        if(Provider) {
            var web3 = new Web3(Provider)
            if(
                web3
                && web3.eth
            ) {
                if(PurchaseCurrency==config.tokenSymbol){
                    var KUKUContract       = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
                    var currAddr             = await localStorage.getItem("address");
                    var tokenBal             = await KUKUContract.methods.balanceOf(currAddr).call();
                    var tokenBalance         = tokenBal / config.decimalvalues;
                    if(tokenBalance==0){
                        toast.error(PurchaseCurrency+" Token Balance Insufficient", toasterOption)
                        return false;
                    }
                    if(YouWillPay>=tokenBalance){
                        toast.error(PurchaseCurrency+" Token Balance Insufficient", toasterOption)
                        return false;
                    }
                   }else{
                    
                    if(PurchaseBalance==0){
                        toast.error("Insufficient"+PurchaseCurrency+"  Balance", toasterOption)
                        return false;
                    }
                    if(YouWillPay>=PurchaseBalance){
                        toast.error("Insufficient"+PurchaseCurrency+"  Balance", toasterOption)
                        return false;
                    }
                   }
                    const BEP721Contract = new web3.eth.Contract(BEP721_ABI, item.contractAddress)
                    let calamount = (item && convert(item.tokenowners_current.tokenPrice * config.decimalvalues));
                    var sendAmount = calamount.toString();
                   console.log(sendAmount,'sendAmountsendAmount')
                    var tokenaddress= PurchaseCurrency==config.tokenSymbol?
                    config.PankukuAddr
                    :
                    PurchaseCurrency==config.currencySymbol?
                    config.singleContract
                    :
                    "";
                    var status=PurchaseCurrency==config.tokenSymbol?
                    true
                    :
                    false;
                    setPurchaseCallStatus('processing');
                    BEP721Contract.methods
                        .salewithToken(
                            PurchaseCurrency==config.tokenSymbol?
                            config.tokenSymbol
                            :
                            config.currencySymbol,
                            item.tokenowners_current.tokenOwner,
                            item.tokenCounts,
                            sendAmount,
                            tokenaddress,
                        )
                        .send({
                            from: props.Accounts
                        })
                        .then(async (result) => {
                            setPurchaseCallStatus('done');
                            var postData = {
                                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                UserAccountAddr: UserAccountAddr, // new owner
                                tokenCounts: item.tokenCounts,
                                tokenType: item.type,
                                NoOfToken: item.type == 721 ? 1 : NoOfToken,
                                transactionHash: result.transactionHash,
                                PurchaseCurrency: PurchaseCurrency,
                            }
                            var Resp = await PurchaseNow_Complete_Action(postData);
                            if (Resp.data && Resp.data.toast && Resp.data.toast.type == 'success') {
                                toast.success("Collectible purchase successfully", toasterOption)
                                window.$('.modal').modal('hide');
                                setTimeout(() => { window.location.reload(); }, 2000);
                            }
                        })
                        .catch((error) => {
                            setPurchaseCallStatus('tryagain');
                            toast.error('Order not placed', toasterOption);
                        })
            }
        }
    }

    const [item, Set_item] = React.useState(props.item);

    async function balanceCheck(){
        try{
            var web3                 = new Web3(window.ethereum);
            var KUKUContract         = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
            var currAddr             = await localStorage.getItem("address");
            var tokenBal             = await KUKUContract.methods.balanceOf(currAddr).call();
            var tokenBalance         = tokenBal / config.decimalvalues;
            Set_dethBln(tokenBalance)
        }catch(err){
        }
    }
    async function getServicefees(){
        try{
          if (window.ethereum) {
            var web3 = new Web3(window.ethereum);
              var CoursetroContract = new web3.eth.Contract(BEP721_ABI, config.singleContract);
                if(PurchaseCurrency==config.currencySymbol){
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  } else if(PurchaseCurrency==config.tokenSymbol){
                    var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                  }else{
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }
           
            var feeValue    = fee/config.decimalvalues;
            setservicefee(feeValue);
          }
        }catch(err){
        }
      }
    useEffect(() => {
        setmyprovdier()
        Set_ValidateError({});
        balanceCheck();
        getServicefees();
        ItemValidation({TokenPrice:TokenPrice});
    }, [
        TokenPrice
    ]);
const setmyprovdier =(async()=>{
    var mydata = await getmylog();
    if(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!="") {
        SetProvdier(mydata.provider);
let web3                = new Web3(mydata.provider);
let KUKUContract      = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
setKUKUContract(KUKUContract);
let BEP721Contract    = new web3.eth.Contract(BEP721_ABI, config.singleContract);
setBEP721Contract(BEP721Contract);
let WBNBContract      = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
setWBNBContract(WBNBContract);
    }
 })


    useImperativeHandle(
        ref,
        () => ({
            async PurchaseNow_Click(item, BuyOwnerDetail={}) {
                var curAddr = await getCurAddr();
                var connectwallet=localStorage.getItem("tfnukukpannft");
                if(!connectwallet){
                    toast.error("Please connect to a Metamask wallet", toasterOption);
                    return false;
                }
                PriceCalculate({
                    quantity:1,
                    price:BuyOwnerDetail.tokenPrice,
                    type:BuyOwnerDetail.type,
                    PurchaseCurrency:BuyOwnerDetail.biddingtoken
                });

                var web3           = new Web3(Provider);
                web3.eth.getBalance(props.Accounts)
                    .then(val => {
                    var balance = val / config.decimalvalues;
                    set_PurchaseBalance(balance)
                  })
                if(BuyOwnerDetail && typeof BuyOwnerDetail.tokenOwner != 'undefined') {
                    item.tokenowners_current = {};
                    item.tokenowners_current = BuyOwnerDetail;
                    setPurchaseCurrency(BuyOwnerDetail.biddingtoken)
                    setSaletoken(BuyOwnerDetail.balance)
                }
                console.log(BuyOwnerDetail.biddingtoken,"user biddingtoken",config.currencySymbol,"currencysymbol", config.tokenSymbol,"token symbol")
                if (BuyOwnerDetail.biddingtoken == config.currencySymbol ) {
                    var web3           = new Web3(window.ethereum);
                    var CoursetroContract = new web3.eth.Contract(BEP721_ABI, config.singleContract);
                    var fee = await CoursetroContract.methods.getServiceFee().call();                   
                    configFee = fee / config.decimalvalues;
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    var newPrice    = BuyOwnerDetail.type == 721 ? BuyOwnerDetail.tokenPrice*config.decimalvalues :
                    NoOfToken *(BuyOwnerDetail.tokenPrice *config.decimalvalues);
                    var per         = (newPrice * fee) / 1e20;
                    var mulWei      = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                    Set_MultipleWei(mulWei);
                    Set_Price(newPrice)
                    web3.eth.getBalance(props.Accounts)
                    .then(val => {
                    var balance = val / config.decimalvalues;
                    set_PurchaseBalance(balance)
                  })
                }else  if (BuyOwnerDetail.biddingtoken == config.tokenSymbol ) {
                    var web3           = new Web3(window.ethereum);
                    var CoursetroContract = new web3.eth.Contract(BEP721_ABI, config.singleContract);
                    var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                    configFee = fee / config.decimalvalues;
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    var newPrice    = BuyOwnerDetail.type == 721 ? 
                    BuyOwnerDetail.tokenPrice*config.decimalvalues :
                    NoOfToken *(BuyOwnerDetail.tokenPrice*config.decimalvalues);
                    var per         = (newPrice * fee) / 1e20;
                    var mulWei      = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                    var fee                 = await CoursetroContract.methods.getKukuServiceFee().call();
                    var web3                = new Web3(window.ethereum);
                    const KUKUContract    = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
                    var aidibalanceinwei    = await KUKUContract.methods.balanceOf(props.Accounts).call()
                    console.log('accounts',props.Accounts,"aidibalanceinwei",aidibalanceinwei );
                    var balance = aidibalanceinwei / config.decimalvalues;
                    console.log(balance,'balancebalancebalance')
                    set_PurchaseBalance(balance)
                }else  {
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721_ABI, config.singleContract);
                          if(BuyOwnerDetail.biddingtoken==config.currencySymbol){
                              var fee = await CoursetroContract.methods.getServiceFee().call();
                              web3.eth.getBalance(props.Accounts)
                              .then(val => {
                              var balance = val / config.decimalvalues;
                              set_PurchaseBalance(balance)
                          })
                            } else if(BuyOwnerDetail.biddingtoken==config.tokenSymbol){
                              var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                              const KUKUContract    = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
                                var aidibalanceinwei    = await KUKUContract.methods.balanceOf(props.Accounts).call()
                                var balance = aidibalanceinwei / config.decimalvalues;
                                set_PurchaseBalance(balance)
                            }else{
                              var fee = await CoursetroContract.methods.getServiceFee().call();
                              web3.eth.getBalance(props.Accounts)
                              .then(val => {
                              var balance = val / config.decimalvalues;
                              set_PurchaseBalance(balance)
                          })
                            }
                      
                    configFee = fee / config.decimalvalues;
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    var newPrice    = BuyOwnerDetail.type == 721 ? BuyOwnerDetail.tokenPrice*config.decimalvalues :
                    NoOfToken *(BuyOwnerDetail.tokenPrice*config.decimalvalues);
                    var per         = (newPrice * fee) / 1e20;
                    var mulWei      = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                } 

                if (BuyOwnerDetail.biddingtoken == config.tokenSymbol) {
                    var web3                = new Web3(window.ethereum);
                     const KUKUContract     = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
                    var aidibalanceinwei    = await KUKUContract.methods.balanceOf(props.Accounts).call()
                    var balance = aidibalanceinwei / config.decimalvalues;
                    set_PurchaseBalance(balance)
                }else{
                    var web3             = new Web3(window.ethereum);
                    web3.eth.getBalance(props.Accounts)
                    .then(val => {
                        var balance = val / config.decimalvalues;
                        set_PurchaseBalance(balance)
                    })
                }
                if(item && item.tokenowners_current && item.tokenowners_current.tokenPrice) {
                    Set_item(item);
                    Set_TokenPrice(item.tokenowners_current.tokenPrice);
                    Set_NoOfToken(1);
                            var tempbid = item.tokenowners_current.biddingtoken == config.tokenSymbol?
                                        config.PankukuAddr
                                        :
                                        item.tokenowners_current.biddingtoken == config.currencySymbol?
                                        config.singleContract:""    
                     
                    set_biddingtokenaddress(tempbid)
                    var configFee = 0
                    var configFee = 0
                    if (item.tokenowners_current.biddingtoken == config.currencySymbol ) {
                        var web3 = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721_ABI, config.singleContract);
                        var fee         = await CoursetroContract.methods.getServiceFee().call();            
                        configFee = fee / config.decimalvalues;
                        set_feeperc(configFee);
                        web3.eth.getBalance(props.Accounts)
                    .then(val => {
                    var balance = val / config.decimalvalues;
                    set_PurchaseBalance(balance)
                  })
                    }else if (item.tokenowners_current.biddingtoken == config.tokenSymbol){
                        var web3 = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721_ABI, config.singleContract);
                        var fee         = await CoursetroContract.methods.getKukuServiceFee().call();
                        configFee = fee / config.decimalvalues;
                        set_feeperc(configFee);
                       
                    }else {
                        var web3 = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721_ABI, config.singleContract);
                        var fee         = await CoursetroContract.methods.getServiceFee().call();
                        configFee = fee / config.decimalvalues;
                        set_feeperc(configFee);
                    }

                    if (item.tokenowners_current.biddingtoken == config.tokenSymbol) {
                        var web3                = new Web3(window.ethereum);
                        const KUKUContract      = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
                        var aidibalanceinwei = await KUKUContract.methods.balanceOf(props.Accounts).call()
                        var balance = aidibalanceinwei / config.decimalvalues;
                        set_PurchaseBalance(balance)
                    } 
                    else{
                        var web3             = new Web3(window.ethereum);
                        web3.eth.getBalance(props.Accounts)
                        .then(val => {
                            var balance = val / config.decimalvalues;
                            set_PurchaseBalance(balance)
                        })
                    }
                    window.$('#PurchaseNow_modal').modal('show');
                }
            }
        }),
    )

    return (
        <div>
            <div class="modal fade primary_modal PurchaseNow_modal" id="PurchaseNow_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                    <h5 class="modal-title" id="buy_modalLabel">Checkout</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close9" onClick={(buymodal)}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body px-0">
                        <div className="row mx-0">
                            <div className="col-12 col-sm-3 px-4">
                                <p className="buy_desc_sm">Owner</p>
                            </div>
                            <div className="col-12 col-md-6 buy_desc_sm_bold">
                                {item.userprofile && item.userprofile.name? item.userprofile && item.userprofile.name : halfAddrShow(item&&item.tokenowners_current&&item.tokenowners_current. tokenOwner)}
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-md-3 px-4">
                                <p className="buy_desc_sm">Buyer</p>
                            </div>
                            <div className="col-12 col-md-6 buy_desc_sm_bold">
                                {MyItemAccountAddr ?  halfAddrShow(MyItemAccountAddr) : halfAddrShow(UserAccountAddr)}
                            </div>
                        </div>
                        <div className="bor_bot_modal mb-3 px-4 "></div>
                        <form className="bid_form" action="#">
                            {item.type == 721 ?(
                                <div className="bor_bot_modal mb-3 px-4 ">
                                    <div class="mb-3 input_grp_style_1">
                                   { !ValidateError.NoOfToken && ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                    </div>
                                </div>
                            ):
                            <div className="bor_bot_modal mb-3 px-4 ">
                                <label for="qty">Quantity{" "}({saletokenbal} available)</label>
                                <div class="mb-3 input_grp_style_1">
                                    <input
                                        type="text"
                                        className="form-control primary_inp text-center"
                                        name="NoOfToken"
                                        id="NoOfToken"
                                        onChange={inputChange}
                                        placeholder="e.g. 2"
                                        autoComplete="off"
                                        value={NoOfToken}
                                        onKeyUp={onKeyUp}
                                    />
                                    {ValidateError.NoOfToken && <span className="text-danger">{ValidateError.NoOfToken}</span>}
                                    {!ValidateError.NoOfToken && ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                </div>
                            </div>}
                        </form>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Your balance</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{UserAccountBal} {config.currencySymbol}</p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Your token balance</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{dethBln} {config.tokenSymbol}</p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Price</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{TokenPrice} {PurchaseCurrency}</p>
                            </div>
                        </div>
                        {/* <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                            <p className="buy_desc_sm">Royality % </p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                            <p className="buy_desc_sm_bold">{item.tokenRoyality} % <span>{item.biddingtoken}</span></p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                            <p className="buy_desc_sm">Royality fee </p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                            <p className="buy_desc_sm_bold">{(((parseFloat((parseFloat(TokenPrice)*(parseFloat(item.tokenRoyality)/100))))*parseFloat(NoOfToken))).toFixed(5)} <span>{item.biddingtoken}</span></p>
                            </div>
                        </div> */}
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Service fee</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{servicefee} %<span>{PurchaseCurrency}</span></p>
                            </div>
                        </div>
                        {/* <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                            <p className="buy_desc_sm">Service fee </p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                            <p className="buy_desc_sm_bold">{(((parseFloat((parseFloat(TokenPrice)*(parseFloat(servicefee)/100))))*parseFloat(NoOfToken))).toFixed(5)} <span>{item.biddingtoken}</span></p>
                            </div>
                        </div> */}
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">You will pay</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{YouWillPay} <span>{PurchaseCurrency}</span></p>
                            </div>
                        </div>
                        <form className="px-4">
                            <div className="text-center">
                                <Button
                                    type="button"
                                    className="primary_btn btn-block"
                                    onClick={() => FormSubmit()}
                                    disabled={(FormSubmitLoading=='processing')}
                                >
                                    {FormSubmitLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                    Proceed to payment
                                </Button>
                                <Button className="cancel_btn  btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
            <div class="modal fade primary_modal PurchaseStep_modal" id="PurchaseStep_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="PurchaseStepCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                        <h5 class="modal-title" id="PurchaseStepLabel">Follow Steps</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="text-center">
                                    <p className="mt-3 purchase_desc text-center">Approve the transaction</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepOne()}
                                        className="btn-block btn_outline_red"
                                        disabled={(ApproveCallStatus=='processing' || ApproveCallStatus=='done')}
                                    >
                                        {ApproveCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {ApproveCallStatus == 'init' && 'Approve'}
                                        {ApproveCallStatus == 'processing' && 'In-progress...'}
                                        {ApproveCallStatus == 'done' && 'Done'}
                                        {ApproveCallStatus == 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                                <div className="text-center my-3">
                                <p className="mt-3 purchase_desc text-center">Send transaction with your wallet</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepTwo()}
                                        className="btn-block btn_outline_red"
                                        // disabled={(ApproveCallStatus!='done' || PurchaseCallStatus=='processing' || PurchaseCallStatus=='done')}
                                    >
                                        {PurchaseCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {PurchaseCallStatus == 'init' && 'Purchase'}
                                        {PurchaseCallStatus == 'processing' && 'In-progress...'}
                                        {PurchaseCallStatus == 'done' && 'Done'}
                                        {PurchaseCallStatus == 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

