import React, {
    forwardRef,
    useImperativeHandle, useEffect
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';

import {
    AddLikeAction,
    GetLikeDataAction
} from '../../actions/v1/token';

import {
    getCurAddr
} from '../../actions/v1/User';
import {getmylog} from "../../helper/walletconnect";
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

export const LikeRef = forwardRef((props, ref) => {
    const [web3, setweb3] = React.useState();
    const [Provider, SetProvdier] = React.useState();

  useEffect(()=>{
    setmyprovdier()
  },[])

  const setmyprovdier =(async()=>{
    var mydata = await getmylog();
    if(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!="") {
        SetProvdier(mydata);
        let web3                = new Web3(mydata.provider);
setweb3(web3);
    }
  })

    async function getLikesDataCall () {
        if(Provider){
        var web3 = new Web3(Provider.provider)
        var currAddr = await getCurAddr();
        if( web3&& web3.eth) {
            var payload = {
                currAddr: Provider.provider
            }
            var check = await GetLikeDataAction(payload);
            if(check && check.data && check.data.records) {
                console.log("list_likeL",check.data.records)
                props.setLikedTokenList(check.data.records);
            }
        }
    }
    }

    useImperativeHandle(
        ref,
        () => ({
            async getLikesData() {
                getLikesDataCall();
            },
            async hitLike(data) {
                if(
                    web3
                    && web3.eth
                    && Provider.address
                ) {
                    var connectwallet=localStorage.getItem("tfnukukpannft");
                    if(!connectwallet){
                      toast.error("Please connect to a Metamask wallet", toasterOption);
                      return false;
                    }
                    var currAddr = Provider.address;
                    var likeData = {
                        currAddr: currAddr,
                        tokenCounts: data.tokenCounts,
                        tokenOwner: data.tokenOwner,
                    }
                    var resp = await AddLikeAction(likeData);
                    if(resp && resp.data && resp.data.toast && resp.data.toast.msg) {
                        if(resp.data.toast.type == 'success') {
                            toast.success(resp.data.toast.msg, toasterOption);
                            if(
                                resp.data.tokenData
                                && resp.data.tokenData.record
                                && typeof resp.data.tokenData.record.likecount != 'undefined'
                            ) {
                                $('.'+data.tokenCounts+'-likecount').html(resp.data.tokenData.record.likecount);
                            }
                        }
                    }
                    getLikesDataCall();
                }
            }
        }),
    )
    return (
      <div></div>
    )
})

