import React, {
    forwardRef,
    useImperativeHandle
} from 'react';
import '@metamask/legacy-web3';
import { InlineShareButtons } from 'sharethis-reactjs';
import config from '../../lib/config';

import { toast } from 'react-toastify';
toast.configure();
var Front_URL = config.Front_URL;

export const ShareNowRef = forwardRef((props, ref) => {
    const [ShareUrl, setShareUrl] = React.useState("");
    const [Show, setShow] = React.useState("");
    useImperativeHandle(
        ref,
        () => ({
            async ShareSocial_Click(item) {
                if (item && item._id) {
                    var url = Front_URL+"/info/"+item.tokenCounts
                    setShareUrl(url);
                    window.$('#share_modal').modal('show');
                    setShow(true)
                }
            }
        }),
    )

    return (
        <div>
            <div class="modal fade primary_modal" id="share_modal" tabindex="-1" role="dialog" aria-labelledby="share_modalCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="share_modalLabel">Share link to this page</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                               
                                <InlineShareButtons
                                config={{
                                    alignment: 'center',
                                    color: 'social',
                                    enabled: true,
                                    font_size: 16,
                                    language: 'en',
                                    networks: [
                                    'telegram',
                                    'facebook',
                                    'twitter',
                                    ],
                                    padding: 12,
                                    radius: 4,
                                    show_total: false,
                                    size: 40,
                                    url: ShareUrl,
                                    image: 'https://bit.ly/2CMhCMC',
                                    description: 'Pankuku NFT',
                                    title: 'Pankuku NFT',
                                    message: 'Hi visit Pankuku NFT and buy Article what You want',
                                    subject: 'Hi visit Pankuku NFT and buy Article what You want',
                                    username: 'Pankuku NFT' 
                                }}
                                />
                                
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

