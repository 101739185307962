import React,{useEffect} from 'react'
import classNames from "classnames";
import { Link } from "react-router-dom";
import config from '../../lib/config';
import kukuapi from "../../ABI/KUKU";
import wethabi from '../../ABI/BEP721'
// material ui
import { AppBar, Toolbar, IconButton, Button, Menu, MenuItem, Typography  } from "@material-ui/core";
import { MenuOpen, Notifications, AccountBalanceWallet, MeetingRoom } from '@material-ui/icons';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import CookiesModal from "../CookiesModal";
import { useCookies } from 'react-cookie';
import { getmylog} from "../../helper/walletconnect";
// import {getmylog } from '../../helper/walletconnect'

const HeaderDashboard =(props)=> {
    const { classes, handleDrawerOpen, open, handleMenu, handleClose, anchorEl } = props;
    const [currAddr, setcurrAddr] = React.useState("");
    const [blns, setBln]          = React.useState(0);
    const [tokenbalance, settokenbalance]        = React.useState(0);
    const [wethbalance, setwethbalance]          = React.useState(0);
    const [cookies, setCookie] = useCookies(['pancake']);

  function onsetcokkie(newName) {
    setCookie('pancake', newName, { path: '/' });
  }


  useEffect(() => {
    getconnect();
  }, []);
    async function getconnect() {
      // let data = getmylog()
          // console.log(data,'walletdata')
          if("wallet" != localStorage.getItem("wtype")){
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        try {
          if (typeof web3 !== "undefined") {
            window.ethereum.enable().then(async function () {
              const web3 = new Web3(window.web3.currentProvider);
              if (window.web3.currentProvider.isMetaMask === true) {
                await web3.eth.getAccounts(async function (error, result) {
                  var currAddr = window.web3.eth.defaultAccount;
                  setcurrAddr(currAddr);
                  var web3 = new Web3(window.ethereum);
                  window.web3.eth.getBalance(result[0],async (error, balance) => {
                      var currbal = balance / 1000000000000000000;
                      setBln(currbal.toFixed(5));
                      var CoursetroContract = new web3.eth.Contract(kukuapi,config.PankukuAddr);
                      var tokenDethBln = await CoursetroContract.methods.balanceOf(currAddr).call();
                      var value = tokenDethBln / 1000000000000000000;
                      settokenbalance(value.toFixed(8));
                      var CoursetroContract1 = new web3.eth.Contract(wethabi,config.WbnbtokenAddr);
                      var tokenDethBln1 = await CoursetroContract1.methods.balanceOf(currAddr).call();
                      var value1 = tokenDethBln1 / 1000000000000000000;
                      setwethbalance(value1.toFixed(8));
                    }
                  );
             
                });
              }
            });
          } else {
          //  toast.success("Please Add Metamask External", toasterOption);
          
          }
        } catch (err) {}
      } else {
       // toast.success("Please Add Metamask External", toasterOption);
      }
    }else{
      var mydata = await getmylog();
      const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
      await web3.eth.getAccounts(async function (error, result) {
        var currAddr = mydata.address;
        setcurrAddr(currAddr);
        var web3 = new Web3(mydata.provider);
        web3.eth.getBalance(result[0],async (error, balance) => {
            var currbal = balance / 1000000000000000000;
            setBln(currbal.toFixed(5));
            var CoursetroContract = new web3.eth.Contract(kukuapi,config.PankukuAddr);
            var tokenDethBln = await CoursetroContract.methods.balanceOf(currAddr).call();
            var value = tokenDethBln / 1000000000000000000;
            settokenbalance(value.toFixed(8));
            var CoursetroContract1 = new web3.eth.Contract(wethabi,config.WbnbtokenAddr);
            var tokenDethBln1 = await CoursetroContract1.methods.balanceOf(currAddr).call();
            var value1 = tokenDethBln1 / 1000000000000000000;
            setwethbalance(value1.toFixed(8));
          }
        );
      });
      let address = await localStorage.getItem("address");
      setcurrAddr(address);
    }
    }
    async function disconnectMetamask() {
      if (localStorage.getItem("tfnukukpannft")||localStorage.getItem("wtype")) {
        localStorage.removeItem("tfnukukpannft");
        localStorage.removeItem("wtype");
        localStorage.removeItem("address");
        window.location.reload(false)
      } 
    }

  return (
    <div>
      <AppBar
          position="fixed"
          className={classes.appBar + " dash_header"}
          fooJon={classNames(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar disableGutters={true}>
          <div className="topbar_left">          
            <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen} className={classes.menuButton}>
              <MenuOpen
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed
                }}
              />
            </IconButton>
            <a href="https://pankuku.net/"><img src={require("../../assets/images/logo.png")} alt="Logo" className="logo_icon"/></a>
          </div>
          <div className="topBarRight">
            {(!localStorage.getItem("tfnukukpannft"))?(
              <Button className="primary_btn" data-toggle="modal" data-target="#wallet_modal">Connect Wallet</Button>
              ):(
                <div>
                <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className="profileButton"
            >
              <AccountBalanceWallet />
              <Typography className="wallet_address">{currAddr}</Typography>
            </IconButton>
            <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
          <MenuItem>
            <div className="dropProfilePic">
              <Typography className="dropProfileName"><span>Balance in BNB</span>: {blns}</Typography>
              <Typography className="dropProfileName"><span>Balance in {config.tokenSymbol}</span>: {tokenbalance}</Typography>
              <Typography className="dropProfileName"><span>Balance in {config.equalTokenSymbol}</span>: {wethbalance}</Typography>
            </div>
          </MenuItem>
          <MenuItem>
            <Link to="/my-items" className="text-dark">My-Items</Link>
            </MenuItem> 
            <MenuItem onClick={()=>disconnectMetamask()}><MeetingRoom /> Logout</MenuItem>
          </Menu>
            </div>
            )
          }
            {/* <a href="#"><Notifications /></a> */}
           {/*  <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className="profileButton"
            >
              <AccountBalanceWallet />
              <Typography className="wallet_address">0x75Cf28f29266A413eC878CE5C4a1c9682C819586</Typography>
            </IconButton>*/}

            
          </div>
        </Toolbar>
        {!(cookies.pancake)&&<CookiesModal onsetcokkie ={onsetcokkie}/>}
      </AppBar>

      

    </div>
)
}

export default HeaderDashboard;