import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';
import KUKU_ABI from '../../ABI/KUKU.json';
import WBNB_ABI from '../../ABI/WBNB.json';
import BEP721 from '../../ABI/BEP721.json';
import isEmpty from "../../lib/isEmpty";
import { Button, TextField } from '@material-ui/core';
import {
  getCurAddr,
  halfAddrShow
} from '../../actions/v1/User';

import {
  TokenCounts_Get_Detail_Action,
  BidApply_ApproveAction,
  acceptBId_Action,
  CancelBid_Action
} from '../../actions/v1/token';

import { toast } from 'react-toastify';
import { getmylog } from "../../helper/walletconnect";
toast.configure();
let toasterOption = config.toasterOption;


const bidprice = [
  { value: config.equalTokenSymbol, label: config.equalTokenSymbol },
  { value: config.tokenSymbol, label: config.tokenSymbol }
]

export const PlaceAndAcceptBidRef = forwardRef((props, ref) => {

  const [BidformSubmit, Set_BidformSubmit] = React.useState(false);
  const [NoOfToken_NeedToSend, Set_NoOfToken_NeedToSend] = React.useState(false);
  const [MetaMaskAmt, setMetaMaskAmt] = React.useState(false);
  const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
  const [AcceptCallStatus, setAccaptCallStatus] = React.useState('init');
  const [biddingtoken, set_biddingtoken] = React.useState(config.tokenSymbol)
  const [wethbalance, set_wethbalance] = React.useState(0)
  const [bidtokenbalance, set_bidtokenbalance] = React.useState(0)
  const [servicefee, setservicefee] = useState(0);
  const [biddingfee, set_biddingfee] = React.useState(config.fee / 1e18);
  const [PurchaseCurrency, setPurchaseCurrency] = useState("KUKU");
  const [typeofcollection, setTypeofcollection] = useState("");
  const [Provider, SetProvdier] = React.useState();
  const [WETHContract, setWETHContract] = React.useState();
  const [KUKUContract, setKUKUContract] = React.useState();
  const [BEP721Contract, setBEP721Contract] = React.useState();


  var {
    Set_WalletConnected,
    Set_UserAccountAddr,
    Set_UserAccountBal,
    Set_AddressUserDetails,
    Set_Accounts,
    Set_MyItemAccountAddr,
    Set_tokenCounts,
    Set_item,
    Set_tokenCounts_Detail,
    Set_MyTokenBalance,
    Set_Bids,
    Set_AccepBidSelect,
    Set_tokenBidAmt,
    Set_NoOfToken,
    Set_ValidateError,
    Set_TokenBalance,
    Set_YouWillPay,
    Set_YouWillPayFee,
    Set_YouWillGet,
    Set_BidApply_ApproveCallStatus,
    Set_BidApply_SignCallStatus,
    setTotaluserbidAmt,
    BidApply_ApproveCallStatus,
    totaluserbidAmt,
    WalletConnected,
    UserAccountAddr,
    UserAccountBal,
    AddressUserDetails,
    Accounts,
    MyItemAccountAddr,
    tokenCounts,
    item,
    tokenCounts_Detail,
    MyTokenBalance,
    Bids,
    AccepBidSelect,
    tokenBidAmt,
    NoOfToken,
    ValidateError,
    TokenBalance,
    YouWillPay,
    YouWillPayFee,
    YouWillGet,
    BidApply_ApproveCallStatus,
    BidApply_SignCallStatus,
    AllowedQuantity,
    Set_Tabname,
    Tabname
  } = props;

  async function PriceCalculate_this(data = {}) {
    console.log("adminlink", data)
    var price = (typeof data.tokenBidAmt != 'undefined') ? data.tokenBidAmt : tokenBidAmt;
    var quantity = (typeof data.NoOfToken != 'undefined') ? data.NoOfToken : NoOfToken;
    if (price == '') { price = 0; }
    if (quantity == '') { quantity = 0; }
    if (isNaN(price) != true && isNaN(quantity) != true) {

      try {
        if (window.ethereum) {
          var web3 = new Web3(window.ethereum);
          var totalPrice = price;
          var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
          if (biddingtoken == config.currencySymbol) {
            var fee = await CoursetroContract.methods.getServiceFee().call();
          } else if (biddingtoken == config.tokenSymbol) {
            var fee = await CoursetroContract.methods.getKukuServiceFee().call();
          } else {
            var fee = await CoursetroContract.methods.getServiceFee().call();
          }
          totalPrice = parseFloat(totalPrice);
          var per = (totalPrice * config.decimalvalues * fee) / 1e20;
          var sendMMAmt = (totalPrice * config.decimalvalues) + per;
          setMetaMaskAmt(sendMMAmt)
          var finalPrice = sendMMAmt / config.decimalvalues;
          var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
          Set_YouWillPay(totalPriceWithFee);
        }
      } catch (err) {
      }
    }
    else {
      Set_YouWillPay(0);
    }
  }
  const Validation_PlaceABid = (chk) => {
    if (chk) {
      var ValidateError = {};

      if (NoOfToken == '') {
        ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
      }
      else if (isNaN(NoOfToken) == true) {
        ValidateError.NoOfToken = '"Quantity" must be a number';
      }
      else if (NoOfToken == 0) {
        ValidateError.NoOfToken = '"Quantity" must be greater than 0';
      }
      else if (NoOfToken > AllowedQuantity) {
        ValidateError.NoOfToken = '"Quantity" must be less than or equal to ' + AllowedQuantity;
      }

      if (tokenBidAmt == '') {
        ValidateError.tokenBidAmt = '"Bid amount" is not allowed to be empty';
      }
      else if (isNaN(tokenBidAmt) == true) {
        ValidateError.tokenBidAmt = '"Bid amount" must be a number';
      }
      else if (tokenBidAmt == 0) {
        ValidateError.tokenBidAmt = '"Bid amount" must be greater than 0';
      }
      else if (item.minimumBid > tokenBidAmt) {
        ValidateError.tokenBidAmt = '"Bid amount" must be higher than or equal to ' + item.minimumBid;
      }
      Set_ValidateError(ValidateError);
      return ValidateError;
    }
  }
  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
      var ValidateError = {};
      Set_ValidateError(ValidateError);
    } else {
      var ValidateError = {};
      ValidateError.NoOfToken = '"Quantity" must be a number';
      Set_NoOfToken("");
      Set_ValidateError(ValidateError);
    }
  }
  const inputChange = (e) => {
    console.log('inputChange');
    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      var value = e.target.value;
      switch (e.target.name) {
        case 'tokenBidAmt':
          if (value != '' && isNaN(value) == false && value > 0) {
            Set_tokenBidAmt(value);
            PriceCalculate_this({ tokenBidAmt: value, biddingtoken: biddingtoken, typeofcollection: typeofcollection });
            ValidateError.tokenBidAmt = '';
            Set_ValidateError(ValidateError);
          }
          else {
            ValidateError.tokenBidAmt = 'Enter Valid price';
            Set_ValidateError(ValidateError);
            Set_tokenBidAmt(value);
            PriceCalculate_this({ tokenBidAmt: value, biddingtoken: biddingtoken, typeofcollection: typeofcollection });
          }
          break;
        case 'NoOfToken':
          Set_NoOfToken(value);
          PriceCalculate_this({ NoOfToken: value, biddingtoken: biddingtoken, typeofcollection: typeofcollection });
          break;
      }
    }
  }

  async function FormSubmit_PlaceABid(e) {
    Set_BidformSubmit(true);
    if (Provider.provider) {
      var web3 = new Web3(Provider.provider);
      var currAddr = Provider.address;
      if (biddingtoken == config.tokenSymbol) {
        var CoursetroContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
      } else {
        var CoursetroContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
      }
      var tokenBal = await CoursetroContract.methods.balanceOf(currAddr).call();
      var balcheck = tokenBal / config.decimalvalues;
      if (balcheck == 0) {
        toast.error("Insufficient" + " " + biddingtoken + " " + "Token Balance", toasterOption)
        return false;
      }
      if (YouWillPay > balcheck) {
        toast.error("Insufficient" + " " + biddingtoken + " " + "Token Balance", toasterOption)
        return false;
      }
      var errors = await Validation_PlaceABid(true);
      var errorsSize = Object.keys(errors).length;
      if (errorsSize != 0) {
        toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
        return false;
      }
      window.$('#place_bid_modal').modal('hide');
      window.$('#edit_bid_modal').modal('hide');
      window.$('#proceed_bid_modal').modal('show');
    }
  }
  async function BidApply_ApproveCall() {
    console.log("bidding_amount11:", item)

    if (!Provider.provider) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }

    var web3 = new Web3(Provider.provider);
    var currAddr = Provider.address;
    if (!currAddr) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
    }

    Set_BidApply_ApproveCallStatus('processing');
    if (biddingtoken == config.tokenSymbol) {
      var CoursetroContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
    } else {
      var CoursetroContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
    }
    var currAddr = Provider.address;
    var tokenBal = await CoursetroContract.methods.balanceOf(currAddr).call();
    var tokenBalance = tokenBal / config.decimalvalues;
    var getAllowance = await CoursetroContract.methods.allowance(currAddr, config.singleContract).call();
    var sendVal = parseFloat(MetaMaskAmt) + parseFloat(getAllowance);
    var approvebal = convert(sendVal);
    if (tokenBalance == 0) {
      toast.error("Insufficient" + " " + biddingtoken + "" + "Token Balance", toasterOption);
      return false;
    }
    if (YouWillPay > tokenBalance) {
      toast.error("Insufficient" + " " + biddingtoken + "" + "Token Balance", toasterOption);
      return false;
    }
    await CoursetroContract
      .methods
      .approve(
        config.singleContract,
        approvebal.toString()
      )
      .send({ from: Accounts })
      .then(async (result) => {
        toast.success("Approve Successfully", toasterOption);
        var BidData = {
          tokenCounts: item.tokenCounts,
          tokenBidAddress: UserAccountAddr,
          tokenBidAmt: tokenBidAmt.toString(),
          NoOfToken: item.type == 721 ? 1 : NoOfToken,
          BidToken: biddingtoken
        }
        var Resp = await BidApply_ApproveAction(BidData);
        if (Resp.data && Resp.data.type && Resp.data.type == 'success') {
          Set_BidApply_ApproveCallStatus('done');
        }
        else {
          toast.error("Approve failed", toasterOption);
          Set_BidApply_ApproveCallStatus('tryagain');
        }
      })
      .catch((error) => {
        toast.error("Approve failed", toasterOption);
        Set_BidApply_ApproveCallStatus('tryagain');
      })
  }

  async function BidApply_SignCall() {
    if (!Provider.provider) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return;
    }
    var web3 = new Web3(Provider.provider);
    var currAddr = Provider.address;
    if (!currAddr) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return;
    }

    Set_BidApply_SignCallStatus('processing');

    web3.eth.personal.sign("Bidding a Art", currAddr, "Bid Placed")
      .then(async (result) => {
        toast.success("Bid sign successfully", toasterOption);
        Set_BidApply_SignCallStatus('done');
        setTimeout(() => window.$('#proceed_bid_modal').modal('hide'), 600);
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(() => {
        toast.error("Sign failed", toasterOption);
        Set_BidApply_SignCallStatus('tryagain');
      })
  }

  async function CancelBid_Proceed(curBid_val) {
    var payload = {
      tokenCounts: curBid_val.tokenCounts,
      tokenBidAddress: curBid_val.tokenBidAddress
    }
    var Resp = await CancelBid_Action(payload);
    if (Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.message) {
      if (Resp.data.toast.type == 'error') {
        toast.error(Resp.data.toast.message, toasterOption);
      }
      else if (Resp.data.toast.type == 'success') {
        toast.success(Resp.data.toast.message, toasterOption);
      }
    }
    setTimeout(() => window.$('.modal').modal('hide'), 600);
    setTimeout(() => window.location.reload(), 1200);

  }
  async function AcceptBid_Proceed() {

    var curAddr = Provider.address;
    if (Provider.provider) {
      var web3 = new Web3(Provider.provider);
      if (NoOfToken_NeedToSend) {
        if (AccepBidSelect.BidToken == config.equalTokenSymbol) {
          var web3 = new Web3(Provider.provider);
          var WBNBContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
          var tokenBal = await WBNBContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()
          var tokenBalance = tokenBal / config.decimalvalues;

        } else if (AccepBidSelect.BidToken == config.tokenSymbol) {
          var web3 = new Web3(Provider.provider);
          var KUKUContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
          var tokenBal = await KUKUContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()
          var tokenBalance = tokenBal / config.decimalvalues;

        } else {
          var web3 = new Web3(Provider.provider);
          var KUKUContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
          var tokenBal = await KUKUContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()
          var tokenBalance = tokenBal / config.decimalvalues;
        }
        var valueesss = (parseFloat(YouWillPayFee) * parseFloat(AccepBidSelect.NoOfToken)) + (parseFloat(AccepBidSelect.tokenBidAmt) * parseFloat(AccepBidSelect.NoOfToken))
        if (tokenBalance >= valueesss) {
          var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
          setAccaptCallStatus('processing');
          var tokenaddress = AccepBidSelect.BidToken == config.tokenSymbol ?
            config.PankukuAddr
            :
            AccepBidSelect.BidToken == config.equalTokenSymbol ?
              config.WbnbtokenAddr
              :
              "";
          var status = AccepBidSelect.BidToken == config.tokenSymbol ?
            false
            :
            true;
          var passAmt = parseFloat(YouWillPayFee) + parseFloat(YouWillGet);
          passAmt = passAmt.toFixed(config.toFixed);
          passAmt = (passAmt * config.decimalvalues).toString();
          var sendAmt = convert(passAmt);
          await CoursetroContract
            .methods
            .acceptBId(
              AccepBidSelect.BidToken,
              AccepBidSelect.tokenBidAddress,
              sendAmt,
              item.tokenCounts,
              tokenaddress,
              // status
            )
            .send({ from: Accounts })
            .then(async (result) => {
              var acceptBId_Payload = {
                tokenCounts: item.tokenCounts,
                NoOfToken: NoOfToken_NeedToSend,
                tokenBidAddress: AccepBidSelect.tokenBidAddress,
                UserAccountAddr_byaccepter: curAddr,
                transactionHash: result.transactionHash,
                currecy: AccepBidSelect.BidToken,
              }
              var Resp = await acceptBId_Action(acceptBId_Payload);
              setAccaptCallStatus('done');
              setTimeout(() => window.$('.modal').modal('hide'), 600);
              window.location.reload();
            })
            .catch((err) => {
              console.log('err', err)
              toast.error("Accept failed", toasterOption);
              setAccaptCallStatus('tryagain');
            })
        } else {
          toast.error("Bidder doesnt have Enough balance", toasterOption)
          return false;
        }

      } else {
      }
    }
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n.toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
  }

  const SelectBidcurrency = (e) => {
    var filter = e.target.value;
    set_biddingtoken(filter)
    getTokenval(filter)
  }
  async function getTokenval(filter) {
    var web3 = new Web3(window.ethereum);
    if (filter == config.equalTokenSymbol) {
      var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
      var fee = await CoursetroContract.methods.getServiceFee().call();
      var feeValue = fee / config.decimalvalues;
      setservicefee(feeValue)
      var totalPrice = parseFloat(tokenBidAmt);
      var per = (totalPrice * config.decimalvalues * fee) / 1e20;
      var sendMMAmt = (totalPrice * config.decimalvalues) + per;
      setMetaMaskAmt(sendMMAmt)
      var finalPrice = sendMMAmt / config.decimalvalues;
      var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      Set_YouWillPay(totalPriceWithFee);
      var WBNBContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
      var currAddr = Provider.address;
      var decimal = await WBNBContract.methods.decimals().call();
      var tokenBal = await WBNBContract.methods.balanceOf(currAddr).call();
      var tokenBalance = tokenBal / config.decimalvalues;
      Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
    } else {
      var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
      var fee = await CoursetroContract.methods.getKukuServiceFee().call();
      var feeValue = fee / config.decimalvalues;
      setservicefee(feeValue)
      var totalPrice = parseFloat(tokenBidAmt);
      var per = (totalPrice * config.decimalvalues * fee) / 1e20;
      var sendMMAmt = (totalPrice * config.decimalvalues) + per;
      setMetaMaskAmt(sendMMAmt)
      var finalPrice = sendMMAmt / config.decimalvalues;
      var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      Set_YouWillPay(totalPriceWithFee);
      var KUKUContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
      var currAddr = Provider.address;
      var decimal = await KUKUContract.methods.decimals().call();
      var tokenBal = await KUKUContract.methods.balanceOf(currAddr).call();
      var tokenBalance = tokenBal / config.decimalvalues;
      Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
    }
  }
  async function Placebidmodal() {
    setTimeout(() => window.$('#place_bid_modal').modal('hide'), 600);
    setTimeout(() => window.location.reload(false), 900);
  }



  useImperativeHandle(
    ref,
    () => ({
      async PlaceABid_Click(item, MyTokenDetail) {
        Set_BidformSubmit(false);
        var connectwallet = localStorage.getItem("tfnukukpannft");
        if (!connectwallet) {
          toast.error("Please connect to a Metamask wallet", toasterOption);
          return false;
        }
        if (Tabname == 'Info') {
          setTypeofcollection(item.type)
          if (item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken) {
            console.log("setPurchaseCurrency", item.tokenowners_current[0].biddingtoken)
            setPurchaseCurrency(item.tokenowners_current[0].biddingtoken)
            set_biddingtoken(item.tokenowners_current[0].biddingtoken)
            try {
              var web3 = new Web3(Provider.provider);
              if (item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken == config.currencySymbol) {
                var KUKUContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
                var currAddr = Provider.address;
                var decimal = await KUKUContract.methods.decimals().call();
                var tokenBal = await KUKUContract.methods.balanceOf(currAddr).call();
                var tokenBalance = tokenBal / config.decimalvalues;
                Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                setPurchaseCurrency(config.tokenSymbol)
                set_biddingtoken(config.tokenSymbol)
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                var feeValue = fee / config.decimalvalues;
                setservicefee(feeValue);
              } else if (item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken == config.tokenSymbol) {
                var KUKUContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
                var currAddr = Provider.address;
                var decimal = await KUKUContract.methods.decimals().call();
                var tokenBal = await KUKUContract.methods.balanceOf(currAddr).call();
                var tokenBalance = tokenBal / config.decimalvalues;
                Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                setPurchaseCurrency(config.tokenSymbol)
                set_biddingtoken(config.tokenSymbol)
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                var feeValue = fee / config.decimalvalues;
                setservicefee(feeValue);
              } else if (item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken == config.equalTokenSymbol) {
                var WETHContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
                var currAddr = Provider.address;
                var decimal = await WETHContract.methods.decimals().call();
                var tokenBal = await WETHContract.methods.balanceOf(currAddr).call();
                var tokenBalance = tokenBal / config.decimalvalues;
                Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                setPurchaseCurrency(config.equalTokenSymbol)
                set_biddingtoken(config.equalTokenSymbol)
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                var fee = await CoursetroContract.methods.getServiceFee().call();
                var feeValue = fee / config.decimalvalues;
                setservicefee(feeValue);
              } else {
                var WBNBContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
                var currAddr = Provider.address;
                var decimal = await WBNBContract.methods.decimals().call();
                var tokenBal = await WBNBContract.methods.balanceOf(currAddr).call();
                var tokenBalance = tokenBal / config.decimalvalues;
                Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                setPurchaseCurrency(config.tokenSymbol)
                set_biddingtoken(config.tokenSymbol)
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                var fee = await CoursetroContract.methods.getServiceFee().call();
                var feeValue = fee / config.decimalvalues;
                setservicefee(feeValue)
              }
            } catch (err) { }
          }
        } else {
          setTypeofcollection(item.type)

          if (item && item.tokenowners_current && item.tokenowners_current.biddingtoken) {
            setPurchaseCurrency(item.tokenowners_current.biddingtoken)
            set_biddingtoken(item.tokenowners_current.biddingtoken)
            try {
              var web3 = new Web3(Provider.provider);
              if (item && item.tokenowners_current && item.tokenowners_current.biddingtoken == config.currencySymbol) {
                var KUKUContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
                var currAddr = Provider.address;
                var decimal = await KUKUContract.methods.decimals().call();
                var tokenBal = await KUKUContract.methods.balanceOf(currAddr).call();
                var tokenBalance = tokenBal / config.decimalvalues;
                Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                setPurchaseCurrency(config.tokenSymbol)
                set_biddingtoken(config.tokenSymbol)
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                var feeValue = fee / config.decimalvalues;
                setservicefee(feeValue);
              } else if (item && item.tokenowners_current && item.tokenowners_current.biddingtoken == config.tokenSymbol) {
                var KUKUContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
                var currAddr = Provider.address;
                var decimal = await KUKUContract.methods.decimals().call();
                var tokenBal = await KUKUContract.methods.balanceOf(currAddr).call();
                var tokenBalance = tokenBal / config.decimalvalues;
                Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                setPurchaseCurrency(config.tokenSymbol)
                set_biddingtoken(config.tokenSymbol)
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                var feeValue = fee / config.decimalvalues;
                setservicefee(feeValue);
              } else if (item && item.tokenowners_current && item.tokenowners_current.biddingtoken == config.equalTokenSymbol) {
                var WETHContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
                var currAddr = Provider.address;
                var decimal = await WETHContract.methods.decimals().call();
                var tokenBal = await WETHContract.methods.balanceOf(currAddr).call();
                var tokenBalance = tokenBal / config.decimalvalues;
                Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                setPurchaseCurrency(config.equalTokenSymbol)
                set_biddingtoken(config.equalTokenSymbol)
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                var fee = await CoursetroContract.methods.getServiceFee().call();
                var feeValue = fee / config.decimalvalues;
                setservicefee(feeValue);
              }
              else {
                var WETHContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
                var currAddr = Provider.address;
                var decimal = await WETHContract.methods.decimals().call();
                var tokenBal = await WETHContract.methods.balanceOf(currAddr).call();
                var tokenBalance = tokenBal / config.decimalvalues;
                Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                setPurchaseCurrency(config.tokenSymbol)
                set_biddingtoken(config.tokenSymbol)
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                if (item && item.tokenowners_current && item.tokenowners_current.biddingtoken == config.currencySymbol) {
                  var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                } else if (item && item.tokenowners_current && item.tokenowners_current.biddingtoken == config.tokenSymbol) {
                  var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                } else {
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                }
                var feeValue = fee / config.decimalvalues;
                setservicefee(feeValue);
              }
            } catch (err) { }
          }
        }
        if (Bids && Bids.myBid && Bids.myBid.tokenBidAmt) {
          set_biddingtoken(Bids.myBid.BidToken)
          Set_NoOfToken(Bids.myBid.NoOfToken);
          if (Bids.myBid.BidToken == config.currencySymbol) {
            var web3 = new Web3(window.ethereum);
            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
            var fee = await CoursetroContract.methods.getKukuServiceFee().call();
            var feeValue = fee / config.decimalvalues;
            setservicefee(feeValue);
            var KUKUContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
            var currAddr = Provider.address;
            var decimal = await KUKUContract.methods.decimals().call();
            var tokenBal = await KUKUContract.methods.balanceOf(currAddr).call();
            var tokenBalance = tokenBal / config.decimalvalues;
            Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
          } else if (Bids.myBid.BidToken == config.tokenSymbol) {
            var web3 = new Web3(window.ethereum);
            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
            var fee = await CoursetroContract.methods.getKukuServiceFee().call();
            var feeValue = fee / config.decimalvalues;
            setservicefee(feeValue);
            var KUKUContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
            var currAddr = Provider.address;
            var decimal = await KUKUContract.methods.decimals().call();
            var tokenBal = await KUKUContract.methods.balanceOf(currAddr).call();
            var tokenBalance = tokenBal / config.decimalvalues;
            Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
          } else if (Bids.myBid.BidToken == config.qualTokenSymbol) {
            var web3 = new Web3(window.ethereum);
            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
            var fee = await CoursetroContract.methods.getServiceFee().call();
            var feeValue = fee / config.decimalvalues;
            setservicefee(feeValue);
            var WETHContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
            var currAddr = Provider.address;
            var decimal = await WETHContract.methods.decimals().call();
            var tokenBal = await WETHContract.methods.balanceOf(currAddr).call();
            var tokenBalance = tokenBal / config.decimalvalues;
            Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
          } else {
            var web3 = new Web3(Provider.provider);
            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
            var fee = await CoursetroContract.methods.getServiceFee().call();
            var feeValue = fee / config.decimalvalues;
            setservicefee(feeValue);
            var WETHContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
            var currAddr = Provider.address;
            var decimal = await WETHContract.methods.decimals().call();
            var tokenBal = await WETHContract.methods.balanceOf(currAddr).call();
            var tokenBalance = tokenBal / config.decimalvalues;
            Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
          }
        }
        window.$('#place_bid_modal').modal('show');
      },
      async PriceCalculate(data = {}) {
        PriceCalculate_this(data);
      },
      async AcceptBid_Select(curBid_val, item) {
        // getfee()
        if (window.ethereum) {
          console.log("acceptlist11", curBid_val)
          if (curBid_val && curBid_val.tokenBidAmt) {
            window.$('#accept_modal').modal('show');
            Set_AccepBidSelect(curBid_val);

            if (MyTokenBalance < curBid_val.pending) {
              Set_NoOfToken_NeedToSend(MyTokenBalance);
              var totalAmt = MyTokenBalance * curBid_val.tokenBidAmt;
            }
            else {
              Set_NoOfToken_NeedToSend(curBid_val.pending);
              var totalAmt = curBid_val.pending * curBid_val.tokenBidAmt;
            }

            try {
              if (window.ethereum) {
                var web3 = new Web3(window.ethereum);
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                if (curBid_val.BidToken == config.currencySymbol) {
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                  var ServiceFee_val = (totalAmt * fee) / 1e20;
                  Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                  set_biddingfee(fee / 1e18)
                  var YouWillGet_Val = totalAmt - ServiceFee_val;
                  Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                  var feeValue = fee / config.decimalvalues;
                  setservicefee(feeValue);
                } else if (curBid_val.BidToken == config.tokenSymbol) {
                  var fee = await CoursetroContract.methods.getKukuServiceFee().call();
                  var ServiceFee_val = (totalAmt * fee) / 1e20;
                  var YouWillGet_Val = totalAmt - ServiceFee_val;
                  Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                  Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                  set_biddingfee(fee / 1e18)
                  var feeValue = fee / config.decimalvalues;
                  setservicefee(feeValue);
                }
                else {
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                  var ServiceFee_val = (totalAmt * fee) / 1e20;
                  var YouWillGet_Val = totalAmt - ServiceFee_val;
                  Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                  set_biddingfee(fee / 1e18)
                  Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                  var feeValue = fee / config.decimalvalues;
                  setservicefee(feeValue);
                }

              }
            } catch (err) {
            }
          }
        }
        else {
          // console.log("called")
          window.$('#connect_modal').modal('show')
        }
      },
      async CancelBid_Select(curBid_val) {
        // getfee()
        if (
          curBid_val
          && curBid_val.pending > 0
          &&
          (
            curBid_val.status == 'pending'
            || curBid_val.status == 'partiallyCompleted'
          )
        ) {
          Set_AccepBidSelect(curBid_val);
          window.$('#cancel_modal').modal('show');
        }
        else {
          window.$('.modal').modal('hide')
        }
      }
    }),
  )

  useEffect(() => {
    setmyprovdier()
    getServicefees();
    Validation_PlaceABid(BidformSubmit);
  }, [
    tokenBidAmt,
    NoOfToken
  ])

  const setmyprovdier = (async () => {
    var mydata = await getmylog();
    if (mydata && mydata.provider && mydata.provider != null && mydata.provider != undefined && mydata.provider != "") {
      SetProvdier(mydata);
      let web3 = new Web3(mydata.provider);
      let WETHContract = new web3.eth.Contract(WBNB_ABI, config.WbnbtokenAddr);
      setWETHContract(WETHContract);
      let KUKUContract = new web3.eth.Contract(KUKU_ABI, config.PankukuAddr);
      setKUKUContract(KUKUContract);
      let BEP721Contract = new web3.eth.Contract(BEP721, config.singleContract);
      setBEP721Contract(BEP721Contract);
    }
  })
  async function getServicefees() {
    try {
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
        if (biddingtoken == config.currencySymbol) {
          var fee = await CoursetroContract.methods.getServiceFee().call();
        } else if (biddingtoken == config.tokenSymbol) {
          var fee = await CoursetroContract.methods.getKukuServiceFee().call();
        }
        else {
          var fee = await CoursetroContract.methods.getServiceFee().call();
        }
        var feeValue = fee / config.decimalvalues;
        setservicefee(feeValue);
      }
    } catch (err) {
    }
  }
  if (Provider) {
    console.log(Provider, 'provider')
  }
  console.log("PutOnSaleType", item)
  return (
    <div>
      <div id="Validation_PlaceABid" onClick={() => Validation_PlaceABid(BidformSubmit)}></div>
      {/* place_bid Modal */}
      <div class="modal fade primary_modal" id="place_bid_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_modalCenteredLabel" aria-hidden="true" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="place_bid_modalLabel">Place a bid</h5>
              <p className="text-center place_bit_desc text-light">You are about to place a bid for</p>
              {/*  <p className="place_bit_desc_2">
                <span className="text_red mr-2">Uniaqua</span>
                by<span className="text_red ml-2">NFTbuyer</span>
              </p>*/}
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(Placebidmodal)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body px-0 pt-0">
              <form className="px-4 bid_form mt-3">
                {/* <label for="bid">Your bid</label> */}
                <div className="bor_bot_modal mb-3">
                  <div className="form-row">
                    <div className="form-group col-md-8">

                      <label className="primary_label" for="qty">Your bid  </label>
                      <div class="mb-1 input_grp_style_1">
                        <input
                          type="number"
                          onKeyDown={(evt) => ["e", "E", "+", "-", ","].includes(evt.key) && evt.preventDefault()}
                          min="0"
                          name="tokenBidAmt"
                          id="tokenBidAmt"
                          class="primary_inp"
                          placeholder="Enter your bid amount"
                          aria-label="bid"
                          aria-describedby="basic-addon2"
                          onChange={inputChange}
                          autoComplete="off"
                        // value={tokenBidAmt}
                        />

                        {ValidateError.tokenBidAmt && <span className="text-danger">{ValidateError.tokenBidAmt}</span>}

                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <label className="primary_label">&nbsp;</label>
                      <select class="d-block custom-select primary_inp" id="TokenCategory1" value={biddingtoken} name="TokenCategory" onChange={SelectBidcurrency}>
                        {bidprice && bidprice.length > 0 && bidprice.map((option, i) => {
                          return (
                            <option value={option.value}>
                              {option.label}
                            </option>)
                        })
                        }
                      </select>
                    </div>
                  </div>

                </div>
                {item.type == config.multipleType && <div class="mb-3 input_grp_style_1">
                  <input
                    type="number"
                    min="1"
                    onKeyDown={(evt) => ["e", "E", "+", "-", ","].includes(evt.key) && evt.preventDefault()}
                    name="NoOfToken"
                    id="NoOfToken"
                    class="primary_inp"
                    placeholder="Enter your bid quantity"
                    onChange={inputChange}
                    autoComplete="off"
                    value={NoOfToken}
                    onKeyUp={onKeyUp}
                  />
                </div>}
                {item.type == config.multipleType &&
                  <label for="qty" className="enter_qty">Enter quantity
                    <span className="label_muted pl-1">({AllowedQuantity} available)</span>
                  </label>
                }
                {ValidateError.NoOfToken && <span className="text-danger">{ValidateError.NoOfToken}</span>}

                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{UserAccountBal} {config.currencySymbol}</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{TokenBalance} {biddingtoken}</p>
                  </div>
                </div>
                <div className="row pb-3">
                  {item.type == 721 && item.PutOnSaleType == "TimedAuction" &&
                    <>
                      <div className="col-12 col-sm-6">
                        <p className="buy_desc_sm">Minimum Bid</p>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <p className="buy_desc_sm_bold">{item.minimumBid} {biddingtoken}</p>
                      </div>
                    </>}
                </div>

                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{servicefee}% <span>{biddingtoken == config.currencySymbol ? config.equalTokenSymbol : biddingtoken}</span></p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{YouWillPay}<span>{biddingtoken}</span></p>
                  </div>
                </div>

                <div className="text-center">
                  {/* data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal" */}
                  <Button className="btn_outline_red btn-block" onClick={() => FormSubmit_PlaceABid()} >Place a bid</Button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal */}
      {/* edit_bid Modal */}
      <div class="modal fade primary_modal" id="edit_bid_modal" tabindex="-1" role="dialog" aria-labelledby="edit_bid_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="edit_bid_modalLabel">Edit a bid</h5>
              <p className="text-center place_bit_desc text-light">You are about to edit a bid for</p>
              <p className="place_bit_desc_2"><span className="text_red mr-2">Uniaqua</span></p>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body mt-3 px-0 pt-0">
              <form className="px-4 bid_form">
                <label for="bid" className="primary_label">Your bid</label>
                <div class="input-group mb-3 input_grp_style_1">
                  <input
                    type="text"
                    name="tokenBidAmt"
                    id="tokenBidAmt"
                    class="primary_inp w-75"
                    placeholder="Enter your bit amount"
                    aria-label="bid"
                    aria-describedby="basic-addon2"
                    onChange={inputChange}
                    value={tokenBidAmt}
                  />
                  <div class="input-group-append">
                    <span class="input-group-text primary_inp" id="basic-addon2">ETH</span>
                  </div>
                </div>
                {ValidateError.tokenBidAmt && <span className="text-danger">{ValidateError.tokenBidAmt}</span>}
                {item.type == config.multipleType && <label for="qty" className="enter_qty">Enter quantity<span className="label_muted pl-1">({AllowedQuantity} available)</span></label>}
                {item.type == config.multipleType && <div class="mb-3 input_grp_style_1">
                  <input
                    type="text"
                    name="NoOfToken"
                    id="NoOfToken"
                    class="primary_inp"
                    placeholder="Enter your bit quantity"
                    onChange={inputChange}
                    value={NoOfToken}
                  />
                </div>}
                {ValidateError.NoOfToken && <span className="text-danger">{ValidateError.NoOfToken}</span>}
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{UserAccountBal} {config.currencySymbol}</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{TokenBalance} {item.biddingtoken}</p>
                  </div>
                </div>

                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{servicefee}% <span>{config.currencySymbol}</span></p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{YouWillPay}</p>
                  </div>
                </div>

                <div className="text-center">
                  {/* data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal" */}
                  <Button className="btn_outline_red btn-block" onClick={() => FormSubmit_PlaceABid()} >Edit a bid</Button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal */}

      {/* proceed_bid Modal */}
      <div class="modal fade primary_modal" id="proceed_bid_modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => window.location.reload(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div className="media approve_media">
                  {BidApply_ApproveCallStatus == 'processing' ?
                    <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                    :
                    BidApply_ApproveCallStatus == "done" ?
                      <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                      :
                      <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  }
                  {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button
                    className="btn-block  btn_outline_red"
                    disabled={(BidApply_ApproveCallStatus == 'processing' || BidApply_ApproveCallStatus == 'done')}
                    onClick={BidApply_ApproveCall}
                  >
                    {BidApply_ApproveCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {BidApply_ApproveCallStatus == 'init' && 'Approve'}
                    {BidApply_ApproveCallStatus == 'processing' && 'In-progress...'}
                    {BidApply_ApproveCallStatus == 'done' && 'Done'}
                    {BidApply_ApproveCallStatus == 'tryagain' && 'Try Again'}
                  </Button>
                </div>
                <div className="media approve_media">
                  {BidApply_SignCallStatus == 'processing' ?
                    <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                    :
                    BidApply_SignCallStatus == "done" ?
                      <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                      :
                      <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  }
                  <div className="media-body">
                    <p className="mt-0 approve_text">Signature</p>
                    <p className="mt-0 approve_desc">Create a signature to place a bid</p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button
                    className="btn-block btn_outline_red"
                    disabled={(BidApply_ApproveCallStatus != 'done' || BidApply_SignCallStatus == 'processing' || BidApply_SignCallStatus == 'done')}
                    onClick={BidApply_SignCall}
                  >
                    {BidApply_SignCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {BidApply_SignCallStatus == 'init' && 'Start'}
                    {BidApply_SignCallStatus == 'processing' && 'In-progress...'}
                    {BidApply_SignCallStatus == 'done' && 'Done'}
                    {BidApply_SignCallStatus == 'tryagain' && 'Try Again'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}

      {/* accept bid Modal */}
      <div class="modal fade primary_modal" data-backdrop="static" id="accept_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="accept_modalLabel">Accept bid</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body px-0">
              <div className="img_accept text-center">
                {
                  item && item.image && item.image.split('.').pop() == "mp4" ?
                    <video src={`${config.ipfsurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
                    : <div>
                      {(item && item.image && item.image.split('.').pop() == 'mp3') ? (
                        <>
                          <img src={`${config.Back_URL}/images/music.png`} alt="Collections" className="img-fluid img_full_w " />
                          <audio src={`${config.ipfsurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio">
                          </audio>
                        </>
                      ) : (
                        <img src={`${config.ipfsurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
                      )}
                    </div>
                }
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept bid for</span>
                <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold pl-2">{halfAddrShow(AccepBidSelect.tokenBidAddress)}</span>
              </p>
              <p className="info_title text-center">{AccepBidSelect.tokenBidAmt} {AccepBidSelect.BidToken} for {AccepBidSelect.pending} {item.tokenName}</p>
              {/* <div className="row mx-0 pb-3">
                      <div className="col-12 col-sm-6 px-4">
                      <p className="buy_desc_sm">Royality % </p>
                      </div>
                      <div className="col-12 col-sm-6 px-4 text-sm-right">
                      <p className="buy_desc_sm_bold">{item.tokenRoyality} % <span>{AccepBidSelect.BidToken}</span></p>
                      </div>
                  </div>
                  <div className="row mx-0 pb-3">
                  <div className="col-12 col-sm-6 px-4">
                      <p className="buy_desc_sm">Royality fee </p>
                      </div>
                      <div className="col-12 col-sm-6 px-4 text-sm-right">
                      <p className="buy_desc_sm_bold">{(((parseFloat((parseFloat(tokenBidAmt)*(parseFloat(item.tokenRoyality)/100))))*parseFloat(NoOfToken))).toFixed(5)} <span>{AccepBidSelect.BidToken}</span></p>
                  </div>
                  </div> */}
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee in %</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{biddingfee}%<span>{AccepBidSelect.BidToken}</span></p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee in {AccepBidSelect.BidToken}</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{YouWillPayFee} <span>{AccepBidSelect.BidToken}</span></p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{YouWillGet} {AccepBidSelect.BidToken}</p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button
                    className="btn_outline_red btn-block"
                    onClick={() => AcceptBid_Proceed()}
                    disabled={(AcceptCallStatus == 'processing')}
                  >
                    {AcceptCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    Accept bid</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close" onClick={() => window.location.reload(false)}>Cancel</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}

      {/* accept bid Modal */}
      <div class="modal fade primary_modal" id="cancel_modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="accept_modalLabel">Cancel bid</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body px-0">
              <div className="img_accept text-center">
                {
                  item && item.image && item.image.split('.').pop() == "mp4" ?
                    <video src={`${config.ipfsurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
                    :
                    item && item.image && item.image.split('.').pop() == "mp3" ?

                      <>
                        <img src={`${config.Back_URL}/images/music.png`} alt="Collections" className="img-fluid " />
                        <audio src={`${config.ipfsurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio">
                        </audio>
                      </>
                      :
                      <img src={`${config.ipfsurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
                }
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to cancel bid for</span>
                <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
              </p>
              <p className="info_title text-center">{AccepBidSelect.tokenBidAmt} {AccepBidSelect.BidToken} for {item.tokenName}</p>
              <form className="px-4">
                <div className="text-center">
                  <Button className="btn_outline_red btn-block" onClick={() => CancelBid_Proceed(AccepBidSelect)}>Cancel bid</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close" onClick={() => window.location.reload(false)}>Cancel</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}
    </div>
  )
})

