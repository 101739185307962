import React, {
    forwardRef,
    useImperativeHandle
} from 'react';
import { Button } from '@material-ui/core';
import '@metamask/legacy-web3'

import config from '../../lib/config';
import {
    ReportRequest,
} from '../../actions/items';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

export const ReportNowRef = forwardRef((props, ref) => {

    const [description, setdescription] = React.useState("");
    const [ValidateError, Set_ValidateError] = React.useState({});
    const [itemId, Set_itemId] = React.useState("");
    const [tokenCounts, Set_tokenCounts] = React.useState("");
    const [reportBtn, Set_reportBtn] = React.useState(false);

    var {
        UserAccountAddr
    } = props;

    useImperativeHandle(
        ref,
        () => ({
            async SubmitReport_Click(item) {
                console.log("report_item_list",item)
                if(item && item._id){
                    Set_itemId(item._id);
                    Set_tokenCounts(item.tokenCounts);
                    window.$('#report_modal').modal('show');
                }
            }
        }),
    )

    const inputChange = (e) => {
        if(e && e.target && typeof e.target.value != 'undefined') {
            var value = e.target.value;
            setdescription(value);
            if(e.target.value !=""){
                Set_ValidateError({});
            }
            
        }
    }

    const ReportValidation = async (data={}) => {

        var ValidateError = {};
        if(description == '' || typeof description=="undefined") {
            ValidateError.description = '"Description" is not allowed to be empty';
        }
        
        Set_ValidateError(ValidateError);
        return ValidateError;
    }

    async function submitReport(){

        var errors = await ReportValidation();
        var errorsSize = Object.keys(errors).length;
        if(errorsSize != 0) {
            toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
            return false;
        }
        var reqData ={
            reportuser:UserAccountAddr,
            description:description,
            itemId:itemId,
            tokenCounts:tokenCounts

        }
        Set_reportBtn(true);
        var response = await ReportRequest(reqData);
        if(response && response.status=="true"){
            toast.success("Successfully submitted your report", toasterOption);
            setdescription("");
            setTimeout(function(){
                Set_reportBtn(false)
                window.$('#report_modal').modal('hide');
            },100)
            
        }else{
            Set_reportBtn(false)
            toast.error('Oops something went wrong.!', toasterOption);
        }

    }

    async function cancelReport(){
        setdescription("");
        window.$('#report_modal').modal('hide');
        Set_ValidateError({});
    }

    return (
        <div>
        <div class="modal fade primary_modal" id="report_modal" tabindex="-1" role="dialog" aria-labelledby="report_modalCenteredLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                <h5 class="modal-title" id="report_modalLabel">Why are you reporting?</h5>
                <button type="button" class="close" onClick={() => cancelReport()} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <p>Tell us how this user violates the rules of the site</p>
                <form>
                    <label className="primary_label" htmlFor="name">Message</label>
                    <textarea onChange={inputChange} value={description} className="form-control primary_inp" rows="3" name="description" placeholder="Tell us some details"></textarea>
                    {ValidateError.description && <span className="text-danger">{ValidateError.description}</span>}
                    <div className="report_btn_grp mt-3">
                    <Button disabled={reportBtn} className="create_btn" onClick={() => submitReport()}>Report</Button>
                    <Button className="cancel_btn ml-2" onClick={() => cancelReport()}>Cancel</Button>
                    </div>
                </form>
                </div>
            </div>
            </div>
      </div>
        </div>
    )
})

